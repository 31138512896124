import React from 'react'
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import LockIcon from './../../images/home/lock1.jsx'
import { useSelector } from 'react-redux';
const StyleBadge = (props) => {
    const theme = useSelector(state => state.authReducer.theme)
    const StyledBadge = styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
          right:props.table ? 0 :props.preview ? 30 : 8,
          top:props.table ? 20 :props.preview ? 100 :37,
          border: `2px solid #fff`,
          backgroundColor:'#fff',
          padding: "0px"
        }
      }));

  return (
    <StyledBadge
    badgeContent={
        <LockIcon theme={theme} />}
  >
   {props.children}
  </StyledBadge>
  )
}

export default StyleBadge;