import {
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import { MUI } from "../../components/CustomComponents";
import { Formik } from "formik";
import * as Yup from "yup";
const CreateUser = (props) => {
  const { CustomTypography, CustomInput, LoginButton } = MUI;

  const renderInputField = (
    label,
    name,
    value,
    placeholder,
    handleChange,
    touched,
    error,
    type
  ) => {
    return (
      <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
        <CustomTypography>{label}</CustomTypography>
        <CustomInput
          error={Boolean(touched && error)}
          name={name}
          value={value}
          type={type ? type : "text"}
          onChange={handleChange}
          placeholder={placeholder}
          size="small"
        />
        <FormHelperText error sx={{ fontFamily: "Inter" }}>
          {touched && error}
        </FormHelperText>
      </FormControl>
    );
  };


  const value=props.value ?{
    userName: props.value.name,
    email: props.value.email,
    password:props.value.password,
    role:props.value.role
  } :{
    userName: "",
    email: "",
    password: "",
    role:'operator'
  }

  return (
    <Formik
      initialValues={value}
      enableReinitialize
      validationSchema={Yup.object().shape({
        userName: Yup.string().required("Please enter user name"),
        email: Yup.string()
          .required("Please enter user email")
          .email("Please enter a valid email"),
        password: Yup.string().required(" Please enter password"),
      })}
      onSubmit={(values) => {
        // handleOnSubmit(values);
        props.handleSubmit(values);
      }}
    >
      {({ errors, handleChange, handleSubmit, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {renderInputField(
            "User Name*",
            "userName",
            values.userName,
            "Enter user name",
            handleChange,
            touched.userName,
            errors.userName
          )}
          {renderInputField(
            "User email*",
            "email",
            values.email,
            "Enter user email",
            handleChange,
            touched.email,
            errors.email
          )}
          {renderInputField(
            "Password*",
            "password",
            values.password,
            "Enter user password",
            handleChange,
            touched.password,
            errors.password
          )}
          <CustomTypography id="demo-simple-select-label">Role*</CustomTypography>
          <FormControl fullWidth>
            <Select
              size="medium"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.role}
              name='role'
              sx={{borderRadius:'8px',fontFamily:'Inter',fontWeight:500, "&:focus": {
                border: "0"
              }}}
              onChange={handleChange}
            >
              <MenuItem value={'admin'}>Admin</MenuItem>
              <MenuItem value={'operator'}>Operator</MenuItem>
              <MenuItem value={'monitor'}>Monitor</MenuItem>
            </Select>
          </FormControl>
          <LoginButton type="submit" sx={{fontSize:'14px', background:'#0075ff'}}>{props.value ? "Update":"Add"}</LoginButton>
        </form>
      )}
    </Formik>
  );
};

export default CreateUser;
