import { InputBase, Typography, Button, Autocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomInput = styled(InputBase)(({ theme }) => ({
  borderRadius: "8px",
  padding: "8px",
  border: "1px solid #D0D5DD",
  backgroundColor: "#fff",
  fontFamily: "var(--text-base)",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 500,
  "& .MuiInputBase-input": {
    backgroundColor: "#fff",
    fontSize: "14px",
    lineHeight: "18px",
    width: "100%",
    fontFamily: "Inter",
  },
}));

const CustomSelect = styled(Autocomplete)(({ theme }) => ({
  borderRadius: "8px",
  // padding: "8px",
  // backgroundColor: "#fff",
  fontFamily: "Inter",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 500,
 
}));

const CustomTypography = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 600,
  color: "#667085",
  padding: "4px",
}));

const LoginButton = styled(Button)(() => ({
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 600,
  color: "#FFF",
  pb: 0.5,
  borderRadius: "8px",
  marginTop: "2rem",
  // width:'100%',
  textTransform: "none",
  backgroundColor: "#087443",
  "&:hover": {
    backgroundColor: "#087443",
  },
}));

export const MUI = {
  CustomInput,
  CustomTypography,
  LoginButton,
  CustomSelect
};
