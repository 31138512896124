import { Box } from "@mui/material";
import React from "react";
import TrashTable from "./TrashTable";
import Sidebar from "../../components/Toolbar/Sidebar";

const Trash = () => {
  return (
    <Sidebar>
      <Box
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          position: "relative",
          height: "calc(100vh - 100px)",
          overflow: "scroll",
          borderRadius:'20px',
          scrollbarWidth: "none",
        }}
      >
        <TrashTable selectableRows={false} theme={"default"} />
      </Box>
    </Sidebar>
  );
};

export default Trash;
