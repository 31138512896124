import React from "react";
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
const PREFIX = 'CreateDialog';

const classes = {
  inputbase: `${PREFIX}-inputbase`,
  alignCenter: `${PREFIX}-alignCenter`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.inputbase}`]: {
    border: "1px solid #D9D9D9",
    padding: "5px 12px",
    width: "100%",
    borderRadius: "2px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    "&::placeholder": {
      color: "#00000040",
    },
  },

  // textArea: {
  //     border: '1px solid #D9D9D9',
  //     padding: '5px 12px',
  //     width: '100%',
  //     borderRadius: '2px',
  //     "&:focus": {
  //         border: '1px solid #D9D9D9 !important',
  //     },
  //     "&::placeholder": {
  //         color: "#00000040",
  //     },
  // }
  [`& .${classes.alignCenter}`]: {
    display: "flex",
    alignItems: "center",
  }
}));

const CreateDialog = (props) => {

  const handleCancel = () => {
    props.handleClose();
    props?.setLock && props?.setLock()
  };

  return (
    <StyledDialog
      sx={{
        "& .MuiDialog-paper": {
          width: "70%",
          position: "relative",
          overflow: "unset",
          borderRadius:'8px'
        },
      }}
      maxWidth={props.title === "Upload"  ? "md": props?.size ? props.size  : "xs"}
      open={props.open}
    >
      {props.title !== "Important Notice"  && (
        <IconButton
          onClick={handleCancel}
          sx={{
            position: "absolute",
            top: -10,
            right: -10,
            backgroundColor: "#fff",
            zIndex: 1,
            padding:0,
            color:'#0075ff',
            "&:hover":{
            backgroundColor: "#fff",
            color:'#0075ff',
            }
          }}
        >
          <CancelIcon/>
        </IconButton>
      )}
      {props.title === "Upload" ? null : (
        <DialogTitle style={{ position: "relative", zIndex: 0 }}>
          {props.lock?.name || props.fileOpen ? (
            <Box className={classes.alignCenter}>
              <Typography variant="toastTitle" pl={1}>
                {" "}
                {props.title}
              </Typography>
            </Box>
          ) : (
            props.title
          )}
        </DialogTitle>
      )}
      <DialogContent dividers sx={{ borderBottom: "none" }}>
        {props.children}
      </DialogContent>
    </StyledDialog>
  );
};

export default CreateDialog;
