import React, { useState } from "react";
import styled from "styled-components";
import TableComponent from "./userTable";
import { Box, Button } from "@mui/material";
import Adduser from "../../images/addUser.svg";
import CreateDialog from "../../components/Dialog/CreateDialog";
import CreateUser from "./createUser";
import Toolbar from "../../components/Toolbar/Toolbar";
import Sidebar from "../../components/Toolbar/Sidebar";

const FrameParent = styled.div`
  position: absolute;
  top: 100px;
  width: 100%;
  height: 827px;
  overflow: hidden;
  color: var(--text-colors-text-secondary);
`;
const Users = () => {
  const [open, setOpen] = useState(false);
  const [currentRow,setCurrentRow] = useState(false)

  const handleClose=(()=>{
    setOpen(false)
    setCurrentRow(false)
  })
  
  return (
    <Sidebar>
      <Toolbar/>
      <FrameParent>
        <Box display={"flex"} justifyContent={"end"} mr={3}>
          <Button
          onClick={()=>setOpen(true)}
            startIcon={<img src={Adduser} alt="adduser" />}
            color="primary"
            variant="contained"
            sx={{ textTransform: "none",backgroundColor:"#0075ff",color:'#fff' }}
          >
            Add user
          </Button>
        </Box>
        <TableComponent setCurrentRow={setCurrentRow} setOpen={setOpen} />
      </FrameParent>
      <CreateDialog
        open={open}
        title={currentRow ? "Update user" :"Add user"}
        // theme={theme}
        handleClose={handleClose}
        close={setOpen}
        children={
          <CreateUser
            value={currentRow}
          // theme={theme}
          />
        }
      />
    </Sidebar>
  );
};

export default Users;
