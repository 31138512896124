import Menu from "@mui/material/Menu";
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";

const customMenu = (parentProps) => {
  const StyledMenu = styled((props) => (
    <Menu elevation={0} {...props} style={{ minWidth: "300px" }} />
  ))((props) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: props.theme.spacing(1),
      minWidth: props.width ? props.width : 180,
      color:
        props.theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : props.theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: props.theme.palette.text.secondary,
          marginRight: props.theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            props.theme.palette.primary.main,
            props.theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return StyledMenu;
};

export default customMenu();
