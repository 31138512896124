

import React from 'react';

const Folder = (({theme,height,width})=>{
  return(
<svg width={width ? width :"10"} height={height ? height : "113"} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 3.5C3.5 2.94305 3.72125 2.4089 4.11508 2.01508C4.5089 1.62125 5.04305 1.4 5.6 1.4C6.15695 1.4 6.6911 1.62125 7.08492 2.01508C7.47875 2.4089 7.7 2.94305 7.7 3.5V4.9H3.5V3.5ZM2.1 4.9V3.5C2.1 2.57174 2.46875 1.6815 3.12513 1.02513C3.7815 0.368749 4.67174 0 5.6 0C6.52826 0 7.4185 0.368749 8.07487 1.02513C8.73125 1.6815 9.1 2.57174 9.1 3.5V4.9H10.5C10.6857 4.9 10.8637 4.97375 10.995 5.10503C11.1262 5.2363 11.2 5.41435 11.2 5.6V11.9C11.2 12.457 10.9788 12.9911 10.5849 13.3849C10.1911 13.7788 9.65695 14 9.1 14H2.1C1.54305 14 1.0089 13.7788 0.615076 13.3849C0.221249 12.9911 0 12.457 0 11.9V5.6C0 5.41435 0.0737499 5.2363 0.205025 5.10503C0.336301 4.97375 0.514348 4.9 0.7 4.9H2.1ZM6.3 9.45C6.3 9.17152 6.41062 8.90445 6.60754 8.70754C6.80445 8.51062 7.07152 8.4 7.35 8.4H7.357C7.63548 8.4 7.90255 8.51062 8.09946 8.70754C8.29637 8.90445 8.407 9.17152 8.407 9.45V9.457C8.407 9.73548 8.29637 10.0025 8.09946 10.1995C7.90255 10.3964 7.63548 10.507 7.357 10.507H7.35C7.07152 10.507 6.80445 10.3964 6.60754 10.1995C6.41062 10.0025 6.3 9.73548 6.3 9.457V9.45Z"  fill={theme ==='classic'? "#000000" : "#000000"}/>
</svg>
      );
})

export default Folder;
