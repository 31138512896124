import axios from "axios";
import { helpers } from "../_helpers";
import { crypto } from "../components/Security/crypto";
const settings = require('../config');
const ApiEndpoint = settings.ApiEndPoint;


const apiEndpoint = ApiEndpoint;


async function getAllUserSettings() {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const data = await axios.get(
        `${apiEndpoint}/settings`,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async function updateSettings(payload,id) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const encryptedData = crypto.encryption(payload)
    try {
      const data = await axios.put(
        `${apiEndpoint}/settings/${id}`,
        encryptedData,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async function deleteSetting(id) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const data = await axios.delete(
        `${apiEndpoint}/settings/${id}`,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }


  export const settingsData = {
    getAllUserSettings,
    updateSettings,
    deleteSetting
  };