import * as React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Delete from "./../../images/delete.svg";
import Editor from "./../../images/editor.svg";
// import ImageEditor from './../../images/imageEditor.svg'
import Download from "./../../images/download.svg";
import Copy from "./../../images/copy.svg";
import Lock from './../../images/Lock.svg'
import UnLock from './../../images/unlock.svg'
import Move from "./../../images/move.svg";
import Share from "./../../images/share.svg";
import More from "./../../images/moreIcon.svg";
import Rename from "./../../images/rename.svg";
import Archive from "./../../images/archieve.svg";
import { Typography } from "@mui/material";
import StyledMenu from "../Menu";
import Favourite from "./../../images/favourite.svg";
import { useSelector } from "react-redux";
import { apiData } from "../../_services/home";
import { crypto } from "../Security/crypto";
export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // props.setEditorMode(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // props.setEditorMode(false)
  };
  
const globalState = useSelector((item)=> item.authReducer)
  const handleMenu = (item) => {
    if (item.name === "Rename") {
      props.setDialogTitle("Rename a file");
      handleClose();
      props.setLock();
      props?.setCurrentRow && props?.setCurrentRow(props.data);
      props.setOpenDialog(true);
    } else if (item.name === "Share") {
      props.setDialogTitle("Share");
      handleClose();
      props?.setCurrentRow && props?.setCurrentRow(props.data);
      props.setOpenDialog(true);
    } else if (item.name === "Move") {
      handleClose();
      props.setPaste({
        type: "Move",
        location: props.location,
        settingId:globalState.settingId
      });
    } else if (item.name === "Copy") {
      handleClose();
      props.setPaste({
        type: "Copy",
        location: props.location,
        settingId:globalState.settingId
      });
    } else if (
      item.name === "Open in Editor" ||
      item.name === "Open in image editor"
    ) {
      handleClose();
      props.setEditorMode(item.name === "Open in Editor" ? "edit" : "image");
      props.setOpenPreview();
    } else if (item.name === "Delete") {
      handleClose();
      props.setPaste({
        type: "Delete",
        location: props.location,
      });
    } else if (item.name === "Lock") {
      handleClose();
      props.setLock({
        type: "Lock",
        location: props.location,
        name: props.name,
      });
    } else if (item.name === "Unlock") {
      handleClose();
      props.setLock({
        type: "Unlock",
        location: props.location,
        name: props.name,
        id:props.data.lock_id
      });
    }else if (item.name === "Unzip") {
      handleClose();
      props.setPaste({
        type: "Unzip",
        location: props.location,
        settingId:globalState.settingId
      });
    }else if (item.name === "Download") {
      getObjectDetails(props);
    }
    else if (item.name === "Add to favourites") {
      handleClose();
      props.setItem(props.location)
    } else if (item.name === "Undo Favourites") {
      handleClose();
      props.setItem({id:props.data.favorite_id})
    }
  };

  const getObjectDetails = (path) => {

    apiData.getDownloadDetails(globalState.settingId,path.location).then((res) => {
      handleClose();
      if (res.data.status) {
        // url(res.data.response);
        const decreptedData = crypto.decryption(res?.data?.data)
        const link = document.createElement('a');
        link.href = decreptedData;
        link.download = `${path.name}`; 
        link.click();
    }})
  };
  const actions = [
    {
      icon: Editor,
      name: "Open in Editor",
    },
    // {
    //     icon: ImageEditor,
    //     name: "Open in image editor"
    // },
    {
      icon: Share,
      name: "Share",
    },
    {
      icon: Favourite,
      name: "Add to favourites",
    },
    {
      icon: Favourite,
      name: "Undo Favourites",
    },
    {
      icon: Download,
      name: "Download",
    },
    {
      icon: Copy,
      name: "Copy",
    },
    {
      icon: Move,
      name: "Move",
    },
    {
      icon: Delete,
      name: "Delete",
    },
    {
      icon: Rename,
      name: "Rename",
    },
   
    {
        icon: Lock,
        name: "Lock"
    },
    {
        icon: UnLock,
        name: "Unlock"
    },
  //   {
  //     icon: Archive,
  //     name: "Unzip"
  // }
  ];
  const isLocked = props.data?.lock
    ? actions.filter((item) => item.name !== "Lock")
    :  actions.filter((item) => item.name !== "Unlock");
    const isFavourite = props.data?.is_favorite
    ? isLocked.filter((item) => item.name !== "Add to favourites")
    : isLocked.filter((item) => item.name !== "Undo Favourites");
  const folderActions =
   // props.data?.type === "folder" ? isLocked.slice(4) : props.data?.type === "zip" ?  isFavourite : isFavourite.slice(0,-1);
    props.data?.type === "folder" ? isLocked.slice(5)  : isFavourite;
  return (
    <div>
      <Button 
        id="demo-customized-button"
        aria-haspopup="true"
        variant="iconButton"
        disableRipple
        disabled={props.disabled}
        disableElevation
        onClick={handleClick}
        disableFocusRipple
        // endIcon={<KeyboardArrowDownIcon />}
      >
        <img src={More} alt="" />
      </Button>
      <StyledMenu
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {folderActions.map((item, index) => {
          return (
            <MenuItem
              key={item.name}
              disabled={props.data.lock && item.name !== "Unlock"}
              style={{ width: "95%", margin: "0px auto" }}
              onClick={() => handleMenu(item)}
              disableRipple
            >
              <img src={item.icon} width={14} alt="" />
              <Typography variant="menu" ml={1}>
                {" "}
                {item.name}
              </Typography>
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
