import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Box, Grid, Typography,Chip } from "@mui/material";
import Folder from "./../../images/folder.svg";
import File from "./../../images/file.svg";
import SortIcon from "./../../images/sortIcon.svg";
import Menu from "./../../components/Card/SettingsMenu.jsx";
import moment from "moment";
import CustomStyles from "../../components/Table/index.js";

const TableComponent = (props) => {
  const [currentRow, setCurrentRow] = useState(false);
 
  const customStyles = CustomStyles

 

  const viewColumns = [
    {
      name: "Account Name",
      selector: (row) => row.setting_name,
      sortable: true,
      cell: (row, column) => {
        // const isSelected = props.selectedItems.some((r) => r === row.id);
        return (
          <Grid
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
              whiteSpace: "wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // minWidth: 30,
                height: 30,
                borderRadius: 1,
              }}
              
            >
              <img
                src={row.type === "folder" ? Folder : File}
                style={{ padding: 0 }}
                alt=""
                width={15}
              />
              {/* <IconHugeviewGrid1  /> */}
            </Box>
            <Typography 
            // onClick={() => handleClick(row)} 
            variant="settingLable" ml={1}>
              {row.setting_name }
              {/* {row.name ? row.name : row.Name && row.Name[0]} */}
            </Typography>
          </Grid>
        );
      },
    },

    {
      name: "Cloud Name",
      selector: (row) => (row.cloud_provider ? row.cloud_provider.name : ""),
      sortable: true,
    },
    {
      name: "Created Date",
      sortable: true,
      selector: (row) =>(row.created_at ? moment(row.created_at).format('YYYY-MM-DD') : "Amazon")
    },
    {
      name: "Default Account",
      // sortable: true,
      selector: (row) =>  {
        return row.default_setting ?  <Chip label="Primary"/> : ""
      },
    },
    // {
    //   name: "Permission",
    //   sortable: true,
    //   selector: (row) => row.permission,
    // },
    {
      name: "Actions",
      right: true,
      cell: (row) => {
        return (
          (
            <Box>
              <Menu
                data={row}
                setCurrentRow={setCurrentRow}
                setOpenDialog={props.setOpenDialog}
                setDialogTitle={props.setDialogTitle}
                location={row?.prefix}
                setItem={props.setItem}
                name={row?.name}
                handlePrimaryAccount={props.handlePrimaryAccount}
                setPaste={props.setPaste}
                setOpenPreview={() => {
                  props.setOpenPreview(true);
                  props.setActiveObject({
                    prefix: row.prefix,
                    title: row.name,
                  });
                }}
                handleDelete={() => props.handleDelete(row?.prefix)}
              />
            </Box>
          )
        );
      },
    },
  ];

  const caseInsensitiveSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA)?.toLowerCase();
      const bField = selector(rowB)?.toLowerCase();
      let comparison = 0;
      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const handleChange = (e) => {
    const newArray = e.selectedRows.map((item) => item.prefix);
    props.setSelectedItems(newArray);
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.prefix === props.paste?.location ||
        row.prefix === currentRow.prefix,
    },
  ];

  return (
    <Box sx={{ m: 1,border:props?.data.length>0 && '1px solid lightGrey', borderRadius:'8px' }}>
      <DataTable
        dense
        noDataComponent="No data available"
        pagination
        noBorder
        sortIcon={
          <img style={{ margin: "0px 0 -2px 6px " }} src={SortIcon} alt="" />
        }
        columns={viewColumns}
        selectableRows={props.action}
        data={props?.data}
        sortFunction={caseInsensitiveSort}
        // selectableRowsHighlight
        conditionalRowStyles={conditionalRowStyles}
        clearSelectedRows={!props.action}
        // selectableRowsComponent={BootyCheckbox}
        onSelectedRowsChange={handleChange}
        customStyles={customStyles}
        highlightOnHover
      />
    </Box>
  );
};

export default TableComponent;
