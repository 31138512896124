import React, {useState,useEffect} from 'react'
// import Header from './Header';
import Toolbar from './Toolbar';
import Header from './Header';


// utils/browserDetection.js
export const getBrowser = () => {
  const userAgent = navigator.userAgent;

  if (/chrome|chromium|crios/i.test(userAgent)) {
    return 'chrome';
  } else if (/firefox|fxios/i.test(userAgent)) {
    return 'firefox';
  } else if (/safari/i.test(userAgent)) {
    return 'safari';
  } else if (/opr\//i.test(userAgent)) {
    return 'opera';
  } else if (/edg/i.test(userAgent)) {
    return 'edge';
  } else {
    return 'unknown';
  }
};


const Sidebar = (props) => {
  const [browser, setBrowser] = useState('unknown');
  
    useEffect(() => {
      const detectedBrowser = getBrowser();
      setBrowser(detectedBrowser);
    }, []);
  
    const getContainerStyle = () => {
      switch (browser) {
        case 'chrome':
        case 'safari':
        case 'opera':
          return { width: '-webkit-fill-available' };
        case 'firefox':
          return { width: '-moz-available' };
        default:
          return { width: 'fill-available' };
      }
    };
  return (
    <>
      <Toolbar />
      <div style={{ backgroundColor: '#EEF4FC', height: '100vh', position: 'relative', paddingTop:'1rem' }}>
        <div style={{ ...getContainerStyle(), position: 'absolute', top: '0px',bottom:'0px', left: '240px', borderRadius: '24px ', margin: '1rem', backgroundColor: '#fff' }}>
           <Header />
          {props.children} 
        </div>
      </div>
    </>

  )
}

export default Sidebar