import  React ,{useState} from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Delete from "./../../images/delete.svg";
import Move from "./../../images/move.svg";
import More from "./../../images/moreIcon.svg";
import Rename from "./../../images/rename.svg";
import { Typography } from "@mui/material";
import StyledMenu from "../Menu";

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // props.setEditorMode(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // props.setEditorMode(false)
  };

  const handleMenu = (item) => {
    if (item.name === "Rename") {
      props.setDialogTitle("Rename a file");
      handleClose();
      props.setOpenDialog(true)
      props.setItem(props.data)
    } else if (item.name === "Set as Default Account") {
      handleClose();
      props.setItem(props.data)
      props.handlePrimaryAccount({
        id:props.data.id,
        title:props.data.name,
        default_setting:true
      })
    } else if (item.name === "Delete") {
      handleClose();
      props.setPaste({
        type: "Delete",
        location: props.data.id,
      });
    } 
  };

  const actions = [  
  
    {
      icon: Move,
      name: "Set as Default Account",
    },
    {
      icon: Rename,
      name: "Rename",
    },
    {
        icon: Delete,
        name: "Delete",
    }
  ];

  const filteredActions = props.data?.default_setting
    ? actions.filter((item) => item.name === "Rename")
    : actions
    
  
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-haspopup="true"
        variant="iconButton"
        disableRipple
        disabled={props.disabled}
        disableElevation
        onClick={handleClick}
        disableFocusRipple
        // endIcon={<KeyboardArrowDownIcon />}
      >
        <img src={More} alt="" />
      </Button>
      <StyledMenu
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {filteredActions.map((item, index) => {
          return (
            <MenuItem
              key={item.name}
              style={{ width: "95%", margin: "0px auto" }}
              onClick={() => handleMenu(item)}
              disableRipple
            >
              <img src={item.icon} width={14} alt="" />
              <Typography variant="menu" ml={1}>
                {" "}
                {item.name}
              </Typography>
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
