import React from 'react';

class DisableRightClick extends React.Component {
  componentDidMount() {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', this.disableContextMenu);
  }

  componentWillUnmount() {
    // Remove event listener when the component is unmounted
    document.removeEventListener('contextmenu', this.disableContextMenu);
  }

  disableContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click behavior
  };

  render() {
    return this.props.children; // Render the children components
  }
}

export default DisableRightClick;