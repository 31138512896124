import React, { forwardRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import BackToTop from "./BackToTop/BackToTop";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import DisableRightClick from "./DisableRightClick";

const Page = forwardRef(
  (
    { children, title = "", isAuthenticated, addClass = null, ...rest },
    ref
  ) => {
    const navigate = useNavigate();
    let location = useLocation();
    useEffect(() => {
      let allow = false;
      if (isAuthenticated &&
        (location.pathname === "/signin" ||
          location.pathname === "/signup" ||
          location.pathname === "/forgotpassword" ||
          location.pathname === "/newpassword")
      ) {
        navigate("/home", { replace: true });
      } else if (
        location.pathname === "/signin" ||
        location.pathname === "/signup" ||
        location.pathname === "/forgotpassword" ||
        location.pathname === "/newpassword"
      ) {
        allow = true;
      } else {
        allow = false;
      }
      if (!allow) {
        if (!isAuthenticated) {
          navigate("/signin", { replace: true });
        }
      }
    }, []);

    return (
      <React.Fragment>
        <DisableRightClick>
          <div ref={ref} {...rest} >
            <Helmet>
              <title>{title}</title>
            </Helmet>
            {children}
          </div>
        </DisableRightClick>
        <BackToTop />
      </React.Fragment>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    isAuthenticated: state.authReducer.auth && state.authReducer.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(Page);
