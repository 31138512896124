import React from 'react'
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from '@mui/material';
const BackDrop = ({open,handleClose}) => {
  return (
    <Backdrop
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
    onClick={handleClose}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
  )
}

export default BackDrop