import React from 'react';

const Progress = (({theme})=>{
  return(
<svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.66634 2.60984V6.37118C5.66634 6.54799 5.5961 6.71756 5.47108 6.84258C5.34605 6.9676 5.17649 7.03784 4.99967 7.03784C4.82286 7.03784 4.65329 6.9676 4.52827 6.84258C4.40325 6.71756 4.33301 6.54799 4.33301 6.37118V2.60984L3.58567 3.35784C3.46049 3.48294 3.29074 3.55318 3.11377 3.55312C2.9368 3.55305 2.7671 3.48269 2.64201 3.35751C2.51691 3.23233 2.44667 3.06258 2.44673 2.88561C2.4468 2.70863 2.51716 2.53894 2.64234 2.41384L4.52834 0.529175C4.65336 0.404195 4.8229 0.333984 4.99967 0.333984C5.17645 0.333984 5.34599 0.404195 5.47101 0.529175L7.35701 2.41451C7.41895 2.47649 7.46807 2.55007 7.50158 2.63104C7.53508 2.71201 7.55231 2.79878 7.55228 2.88641C7.55225 2.97404 7.53496 3.0608 7.5014 3.14175C7.46783 3.22269 7.41866 3.29624 7.35667 3.35818C7.29469 3.42012 7.22111 3.46924 7.14014 3.50275C7.05918 3.53625 6.9724 3.55348 6.88477 3.55345C6.79714 3.55342 6.71038 3.53613 6.62944 3.50257C6.54849 3.469 6.47495 3.41983 6.41301 3.35784L5.66634 2.60984ZM0.999674 8.33384H8.99967C9.17649 8.33384 9.34605 8.40408 9.47108 8.5291C9.5961 8.65413 9.66634 8.8237 9.66634 9.00051C9.66634 9.17732 9.5961 9.34689 9.47108 9.47191C9.34605 9.59694 9.17649 9.66718 8.99967 9.66718H0.999674C0.822863 9.66718 0.653294 9.59694 0.52827 9.47191C0.403246 9.34689 0.333008 9.17732 0.333008 9.00051C0.333008 8.8237 0.403246 8.65413 0.52827 8.5291C0.653294 8.40408 0.822863 8.33384 0.999674 8.33384Z" fill={theme ==='classic'? "#4CB5F5" : "#4CB5F5"}/>
</svg>
      );
})

export default Progress;