import DOC from "./../../images/files/doc.svg";
import Image from "./../../images/files/Img.svg";
import Video from "./../../images/files/video.svg";
import ODP from "./../../images/files/ODP.svg";
import DOCX from "./../../images/files/DOCX.svg";
import ODS from "./../../images/files/ODS.svg";
import ODT from "./../../images/files/ODT.svg";
import OTT from "./../../images/files/OTT.svg";
import PDF from "./../../images/files/PDF.svg";
import PPT from "./../../images/files/PPT.svg";
import RTF from "./../../images/files/RTF.svg";
import XLS from "./../../images/files/XLS.svg";
import CSV from "./../../images/files/CSV.svg";
import DJVU from "./../../images/files/DJVU.svg";
import XLSX from "./../../images/files/XLSX.svg";
import TXT from "./../../images/files/TXT.svg";
import Audio from "./../../images/files/audio.svg";
import Folder from "./../../images/files/folder.svg";
import BAT from "./../../images/files/BAT.svg";
import C from "./../../images/files/C.svg";
import CLASS from "./../../images/files/CLASS.svg";
import CPP from "./../../images/files/CPP.svg";
import CS from "./../../images/files/CS.svg";
import CSS from "./../../images/files/CSS.svg";
import GO from "./../../images/files/GO.svg";
import H from "./../../images/files/H.svg";
import HTACCESS from "./../../images/files/HTACCESS.svg";
import HTML from "./../../images/files/HTML.svg";
import JAVA from "./../../images/files/JAVA.svg";
import JS from "./../../images/files/JS.svg";
import JSON from "./../../images/files/JSON.svg";
import KML from "./../../images/files/KML.svg";
import PL from "./../../images/files/PL.svg";
import PY from "./../../images/files/PY.svg";
import RB from "./../../images/files/RB.svg";
import SH from "./../../images/files/SH.svg";
import SQL from "./../../images/files/SQL.svg";
import SWIFT from "./../../images/files/SWIFT.svg";
import TEST from "./../../images/files/TEST.svg";
import VB from "./../../images/files/VB.svg";
import YAML from "./../../images/files/YAML.svg";
import ZIP from "./../../images/files/ZIP.svg";


export const FileType = {
  getFileType,
};

function getFileType(type) {
  const extension = type?.split(".").pop();
  if (extension) {
    return extension === "docx"
      ? DOCX
      : extension === "txt"
      ? TXT
      : extension === "doc"
      ? DOC
      : extension === "bat"
      ? BAT
      : extension === "c"
      ? C
      : extension === "class"
      ? CLASS
      : extension === "cpp"
      ? CPP
      : extension === "cs"
      ? CS
      : extension === "css"
      ? CSS
      : extension === "go"
      ? GO
      : extension === "h"
      ? H
      : extension === "htaccess"
      ? HTACCESS
      : extension === "html"
      ? HTML
      : extension === "java"
      ? JAVA
      : extension === "js"
      ? JS
      : extension === "java"
      ? JAVA
      : extension === "json"
      ? JSON
      : extension === "kml"
      ? KML
      : extension === "pl"
      ? PL
      : extension === "py"
      ? PY
      : extension === "rb"
      ? RB
      : extension === "sh"
      ? SH
      : extension === "sql"
      ? SQL
      : extension === "swift"
      ? SWIFT
      : extension === "test"
      ? TEST
      : extension === "zip"
      ? ZIP
      : extension === "vb"
      ? VB
      : extension === "yaml"
      ? YAML
      : extension === "odp"
      ? ODP
      : extension === "docx"
      ? DOCX
      : extension === "xlsx"
      ? XLSX
      : extension === "xls"
      ? XLS
      : extension === "ods"
      ? ODS
      : extension === "odt"
      ? ODT
      : extension === "ott"
      ? OTT
      : extension === "pdf"
      ? PDF
      : extension === "ppt"
      ? PPT
      : extension === "rtf"
      ? RTF
      : extension === "jpg" ||
        extension === "jpeg" ||
        extension === "svg" ||
        extension === "png" ||
        extension === "cr2" ||
        extension === "dng" ||
        extension === "erf" ||
        extension === "heic" ||
        extension === "heif" ||
        extension === "jfif" ||
        extension === "jp2" ||
        extension === "nrw" ||
        extension === "orf" ||
        extension === "pef" ||
        extension === "pes" ||
        extension === "raf" ||
        extension === "rw2" ||
        extension === "webp" ||
        extension === "cur" ||
        extension === "dds" ||
        extension === "exr" ||
        extension === "fts" ||
        extension === "gif" ||
        extension === "hdr" ||
        extension === "ico" ||
        extension === "jpe" ||
        extension === "jps" ||
        extension === "mng" ||
        extension === "pam" ||
        extension === "bmp" ||
        extension === "pbm" ||
        extension === "pcd" ||
        extension === "pcx" ||
        extension === "pfm" ||
        extension === "pgm" ||
        extension === "picon" ||
        extension === "pict" ||
        extension === "pnm" ||
        extension === "ppm" ||
        extension === "psd" ||
        extension === "ras" ||
        extension === "sfw" ||
        extension === "sgi" ||
        extension === "tga" ||
        extension === "tiff" ||
        extension === "wbmp" ||
        extension === "webp" ||
        extension === "xbm" ||
        extension === "wpg" ||
        extension === "x3f" ||
        extension === "xcf" ||
        extension === "xpm" ||
        extension === "xwd"
      ? Image
      : extension === "djvu"
      ? DJVU
      : extension === "csv"
      ? CSV
      : extension === "mp4" ||
        extension === "3gp" ||
        extension === "asf" ||
        extension === "avi" ||
        extension === "f4v" ||
        extension === "flv" ||
        extension === "hevc" ||
        extension === "m2ts" ||
        extension === "m2v" ||
        extension === "m4v" ||
        extension === "mjpeg" ||
        extension === "mov" ||
        extension === "mkv"
      ? Video
      : extension === "mp3" ||
        extension === "crdownload" ||
        extension === "m4r" ||
        extension === "oga" ||
        extension === "tta" ||
        extension === "ircam" ||
        extension === "8svx" ||
        extension === "aac" ||
        extension === "ac3" ||
        extension === "aiff" ||
        extension === "amb" ||
        extension === "au" ||
        extension === "avr" ||
        extension === "cdda" ||
        extension === "cvs" ||
        extension === "cvsd" ||
        extension === "cvu" ||
        extension === "dts" ||
        extension === "dvms" ||
        extension === "flac" ||
        extension === "gsrt" ||
        extension === "hcom" ||
        extension === "htk" ||
        extension === "ima" ||
        extension === "ircam" ||
        extension === "m4a" ||
        extension === "maud" ||
        extension === "mp2" ||
        extension === "nist" ||
        extension === "ogg" ||
        extension === "opus" ||
        extension === "prc" ||
        extension === "pvf" ||
        extension === "ra" ||
        extension === "sd2" ||
        extension === "sln" ||
        extension === "smp" ||
        extension === "sndr" ||
        extension === "sndt" ||
        extension === "sou" ||
        extension === "sph" ||
        extension === "spx" ||
        extension === "txw" ||
        extension === "vms" ||
        extension === "voc" ||
        extension === "vox" ||
        extension === "w64" ||
        extension === "wav" ||
        extension === "wma" ||
        extension === "wv" ||
        extension === "wve"
      ? Audio
      : DOC;
  } else {
    return Folder;
  }
}
