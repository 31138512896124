import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }
:root {

/* fonts */
--text-base: Inter;

/* font sizes */
--font-size-7xl: 26px;
--text-base-size: 15px;
--font-size-sm: 14px;

/* Colors */
--neutral-neutral-0: #fff;
--layout-border: #e1e5ea;
--text-colors-text-muted: #929fb1;
--text-colors-text-base: #181d25;
--text-colors-text-secondary: #606e80;
--main-colors-primary-soft: #ebf1ff;
--layout-surface-subtle: #f6f7f9;
--neutral-neutral-100: #edf0f2;
--neutral-neutral-opacity-8: rgba(90, 97, 109, 0.08);
--layout-border-intense: #c3ced7;
--main-colors-primary: #0075ff;

/* Spacing */
--numbers-gap-sm-8: 8px;
--numbers-radius-base: 8px;
--numbers-radius-sm: 6px;

/* Gaps */
--gap-5xs: 8px;
--gap-7xs: 6px;
--gap-xs: 12px;

/* Paddings */
--padding-5xs: 8px;
--padding-3xs: 10px;
--padding-xs: 12px;
--padding-7xs: 6px;
--padding-9xs: 4px;
--padding-31xl: 50px;
--padding-7xl: 26px;
--padding-11xs: 2px;

/* Border radiuses */
--br-5xs: 8px;
--br-7xs: 6px;

/* Effects */
--button-effect: 0px 8px 16px rgba(255, 255, 255, 0.2) inset, 0px 2px 0px rgba(255, 255, 255, 0.16) inset;
}
`;
