import React from "react";
import DataTable from "react-data-table-component";
import { Box, Chip,  IconButton, } from "@mui/material";
import SortIcon from "./../../images/sortIcon.svg";
import customStyles1 from "../../components/Table/index.js";
import { Delete, Edit } from "@mui/icons-material";
const TableComponent = (props) => {

  const customStyles = customStyles1


  

;

  

  const viewColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      sortable: true,
      selector: (row) => row.role,
      cell:(row)=>{
        return(
          <Chip label={row.role}/>
        )
      }
    },
    {
      name: "Action",
      // sortable: true,
      right:true,
      selector: (row) => row.role,
      cell: (row) => {
        return (
          <Box>
            <IconButton sx={{padding:'0 10px'}}>
            <Edit sx={{color:'grey'}} onClick={()=>editUser(row)}/>
            </IconButton>
            <IconButton sx={{padding:'0'}}>
            <Delete sx={{color:'grey'}} onClick={deleteUser}/>
            </IconButton>
          </Box>
        )}
    }, 

  ];

  const editUser =((row)=>{
    props.setCurrentRow(row)
    props.setOpen(true)
  })

  const deleteUser =(()=>{
    props.setOpen(false)
  })




  const data=[{
    name:'Arun',
    email:'arun@gmail.com',
    role:'admin',
    password:'1234'
  },
  {
    name:'Vanavarayan',
    email:'vanava@gmail.com',
    role:'monitor',
    password:'12346'
  },
  {
    name:'John',
    email:'john@gmail.com',
    role:'operator',
    password:'12345'
  }]
  return (
    <Box sx={{ m: 1 ,border:'2px solid lightGrey', borderRadius:'8px'}}>
      <DataTable
        dense
        noDataComponent="No data available"
        pagination
        noBorder
        sortIcon={
          <img style={{ margin: "0px 0 -2px 6px " }} src={SortIcon} alt="" />
        }
        columns={viewColumns}
        data={data}
        customStyles={customStyles}
        highlightOnHover
      />
    </Box>
  );
};

export default TableComponent;
