import React, { useState } from "react";
import { Toolbar, Box,  MenuItem, Menu, Stack, Typography,  Tooltip, Button,  IconButton,  Grid, Avatar, CircularProgress, Badge } from '@mui/material';
import { connect, useSelector } from "react-redux";
import BellIcon from "../../images/home/bell.svg";
import Cookies from 'universal-cookie';
import StyledMenu from "../../components/Menu/index";
import ProgressIcon from "./../../images/home/uploadProgress.jsx";
import { useNavigate } from 'react-router-dom';
import { logoutSuccess, setFiles,setProgress } from "../../store/bugs";

const Header = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const globalState = useSelector((state) => state.authReducer);
  const [type, setType] = useState();
  const openMenu = Boolean(anchorEl);
  const cookies = new Cookies();
  const navigate = useNavigate();



  const handleClose = ((event) => {
    setAnchorEl(false);
  })

  const handleClick = (event, state) => {
    setType(state);
    setAnchorEl(event.currentTarget);
  };


  const clearFiles = (() => {
    props.setFiles({ files: [] })
    props.setProgress({ progress: {} })
  })


  const logout = () => {
    props.logoutSuccess();
    cookies.remove("token");
    cookies.remove("bucketName");
    navigate("/signin", { replace: true });
  };


  const ProgresIcon = (({ value }) => {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress size={30} variant="determinate" sx={{ color: "#4CB5F5" }} value={value} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ProgressIcon theme={"theme"} />
        </Box>
      </Box>
    )
  })
  return (
    <>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', textAlign: 'center', marginBottom: '-10px' }}>

        </Box>

        {(
          <>
            <IconButton onClick={(e) => handleClick(e, "notification")}>
              {
                globalState.files?.length === 0 || globalState.files === undefined ?
              <img src={BellIcon} width={25} alt="" />
                :
              <Badge badgeContent={globalState.files?.length} sx={{
                "&.MuiBadge-dot,.MuiBadge-anchorOriginTopRight ":{
                  backgroundColor:'#0075FF',
                  color:'#fff'
                }
                }}>
              <img src={BellIcon} width={25} alt="" />
              </Badge>
              }
            </IconButton>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => handleClick(e, "profile")}
                color="inherit"
              >
                {/* <AccountCircle /> */}
                <Avatar sx={{ bgcolor: '#0075FF', width: 40, height: 40,textTransform:'capitalize' }}>{globalState.token?.email?.charAt(0)}</Avatar>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl) && type !== "notification"}
                onClose={handleClose}
              >
                <MenuItem sx={{ fontFamily: 'Inter', fontWeight: 500, px: '32px' }}
                  onClick={() => navigate('/profile')}
                >Profile</MenuItem>
                <MenuItem sx={{ fontFamily: 'Inter', fontWeight: 500, px: '32px' }}
                  onClick={() => logout()}
                >Logout</MenuItem>
              </Menu>
            </div>
          </>
        )}
      </Toolbar>
      <StyledMenu
        width={type ? 500 : 240}
        // position="left"
        id="demo-customized-menu"
        MenuListProps={{
          id: "hghg",
          "aria-labelledby": "demo-customized-button",
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 5,
            padding: '1rem 2rem 0'
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorEl={anchorEl}
        open={openMenu && type === "notification"}
        onClose={handleClose}
      >
        <Box

          sx={{
            margin: "auto",
            color: "#041527",
            padding: '1rem 0',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid lightGrey",
          }}
        >
          <Typography
            variant="action"
            style={{ color: "#041527", fontSize: '16px' }}
          >
            {type ? "Notifications" : "Settings"}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            {/* <img width={20} onClick={handleClose} src={CloseIcon} alt="" /> */}
          </IconButton>
        </Box>
        {!type ? null : (
          <Box>
            <Stack style={{
              maxHeight: '280px', overflowY: 'scroll', scrollbarWidth: 'none', ":hover": {
                scrollbarWidth: 'thin'
              }, ':-webkit-scrollbar': {
                width: '4px', // Scrollbar width
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '10px !important', // Scrollbar radius
              },
              ':-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555 !important',
              },
            }}>
              {
                (globalState.files?.length === 0 || globalState.files === undefined) ?
                  <Typography variant="menu" style={{ textAlign: 'center', padding: '2rem', fontSize: '1rem' }}>No notification found </Typography> :
                  <Box>
                    {globalState.files.map((item, index) => {
                      return (
                        <Grid
                          key={item.name}
                          container
                          display="flex"
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          sx={{ borderBottom: '1px solid lightGrey' }}
                        >
                          <Box display="flex" alignItems={"center"}>
                            <Box alignItems={'center'} display={'flex'} justifyContent={'center'}>
                              {/* <ProgresIcon value={globalState.progress[item.name] || 0}/> */}
                              <ProgresIcon value={globalState.progress[item.name] || 0} />
                            </Box>
                            <Box p={2} sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "50ch"
                            }}>
                              <Typography variant="upload" sx={{ fontSize: "14px" }}>
                              {item.type} Status
                              </Typography>
                              <br />
                              <Tooltip title={item.name} placement="bottom-start">
                                <Typography variant="upload" sx={{ color: 'grey' }}>{item.name}</Typography>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box>
                          {item.type === 'Upload' ?<Typography variant="upload" sx={{ color: 'grey' }}>{`${parseFloat(item.size / 1024 / 1024).toFixed(2)}MB`}</Typography>: null}
                          </Box>
                        </Grid>
                      );
                    })}

                  </Box>
              }
            </Stack>
            {globalState.files && globalState.files.length > 0 && <Button variant="completed" disableElevation disableTouchRipple disableFocusRipple
              onClick={clearFiles}
            >
              Clear completed
            </Button>}
          </Box>
        )}
      </StyledMenu>
    </>

  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  setFiles: (files) => dispatch(setFiles(files)),
  setProgress: (progress) => dispatch(setProgress(progress)),

});

export default connect(mapStateToProps, dispatchToProps)(Header);