import Cookies from 'universal-cookie';
import axios from 'axios'

const cookies = new Cookies();


export const helpers = {
    getCookies,
    setHeader,

};

function getCookies(name){
    return cookies.get(name)
}

function setHeader() {
    const token = helpers.getCookies("token")
    if (token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
}




