import {
  Box,
  Container,
  FormControl,
  Card,
  FormHelperText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Logo from "./../../images/home/logo.png";
import { MUI } from "../../components/CustomComponents";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { loginApi } from "../../_services/login";
import Alert from "../../components/alert";
import { setToken, loginSuccess} from '../../store/bugs'
import { connect } from 'react-redux';
import Page from "../../components/page";
import Cookies from 'universal-cookie'


const PREFIX = "NormalCard";
const classes = {
  container: `${PREFIX}-container`,
  anchor: `${PREFIX}-anchor`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    minHeight: "100vh",
    alignItems: "center",
    display: "flex",
    background:'#EEF4FC',
    maxWidth:'630px'
  },
}));

const SignIn = (props) => {
  const { CustomTypography, CustomInput, LoginButton } = MUI;
  const [severity,setSeverity]= useState(false)
  const [message,setMessage]= useState(false)
  const [open,setOpen]= useState(false)
  const [loading,setLoading]= useState(false)
  const [workspace,setWorkspace] = useState(false)
  const cookies = new Cookies();


  const navigate = useNavigate();
  const renderInputField = (
    label,
    name,
    value,
    placeholder,
    handleChange,
    touched,
    error,
    type
  ) => {
    return (
      <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
        <CustomTypography>{label}</CustomTypography>
        <CustomInput
          error={Boolean(touched && error)}
          name={name}
          value={value}
          type={type ? type : "text"}
          onChange={handleChange}
          placeholder={placeholder}
          size="small"
        />
        <FormHelperText error sx={{ fontFamily: "Inter" }}>
          {touched && error}
        </FormHelperText>
      </FormControl>
    );
  };

  const renderTitle = (title, description) => {
    return (
      <Box
        alignItems={"center"}
        mb={3}
        display={"flex"}
        flexDirection={"column"}
      >
        <img
          // style={{ marginBottom: "1rem" }}
          src={Logo}
          width={300}
          alt="logo"
        />
     
        <CustomTypography sx={{ color: "#475467" }}>
          {description}
        </CustomTypography>
      </Box>
    );
  };

  const handleClose=(()=>{
    setOpen(false)
  })

  const handleOnSubmit =async (values) => {
try {
  const data = {
    "email": values.email,
    "password":values.password,
    "workspace_name": values.workSpace
  }
    setLoading(true)
    await loginApi.login(data).then((res) => { 
    setLoading(false)
      if (res && res.status === 200) {
        setOpen(true);
        setSeverity('success')
        setMessage('Login Success')
        setSeverity('success')
        cookies.set('token', res.data.token);
        props.setToken({ token: {
          email:data.email,
          workspace:data.workspace_name
        }});  
        const auth = {loggedIn : true}
        props.loginSuccess({auth:auth})
        setTimeout(() => {
          navigate('/home')
        }, 1500);
      } else if(res && res.status === 404 && res.data.message === "Workspace Not found."){
        setOpen(true)
        setWorkspace(true)
        setSeverity('error')
        setMessage("Workspace not found")
      }else {
        setOpen(true)
        setSeverity('error')
        setMessage(res.data.message)
      }
    });
} catch (error) {
  setLoading(false)
    setOpen(true)
    setSeverity('error')
    setMessage('Login Failed')
}
  
  };


 
  return (
    <Root>
      <Page>
        <Box sx={{background:'#EEF4FC'}}>
      <Container className={classes.container}>
        <Card
          sx={{
            width: "100%",
            boxShadow: "1px 1px 10px 0px lightGrey",
            borderRadius:'8px',
            minHeight: "80vh",
            paddingTop: "40px",
            margin:'1rem',
          }}
        >
          <Formik
            initialValues={{
              workSpace: "",
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              workSpace: Yup.string().required("Please enter workSpace"),
              email: Yup.string()
                .required("Please enter email")
                .email("Please enter a valid email"),
              password: Yup.string().required(" Please enter password"),
            })}
            onSubmit={(values) => {
              handleOnSubmit(values);
            }}
          >
            {({
              errors,
              setFieldValue,
              handleChange,
              handleSubmit,
              touched,
              submitCount,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}

                <Container maxWidth={"xs"}>
                  {renderTitle("Cloud Zilla", "Explore the new file world")}
                  <Box sx={{ minHeight: 300 }}>
                    {renderInputField(
                      "Workspace*",
                      "workSpace",
                      values.workSpace,
                      "Enter your workspace",
                      handleChange,
                      touched.workSpace,
                      errors.workSpace
                    )}
                    {renderInputField(
                      "Email*",
                      "email",
                      values.email,
                      "Enter your email",
                      handleChange,
                      touched.email,
                      errors.email
                    )}

                    {renderInputField(
                      "Password*",
                      "password",
                      values.password,
                      "Enter your password",
                      handleChange,
                      touched.password,
                      errors.password,
                      "password"
                    )}
                    <Box sx={{float:'right'}}>
                      <Typography
                        // color="primary"
                        sx={{
                          marginTop: "8px",
                          fontFamily: "Inter",
                          textDecoration: "underline",
                          fontSize: 12,
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/forgotpassword")}
                      >
                        Forgot Password?
                      </Typography>
                     {workspace && <Typography
                        // color="primary"
                        sx={{
                          marginTop: "8px",
                          fontFamily: "Inter",
                          textDecoration: "underline",
                          fontSize: 12,
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/forgotworkspace",{state:{workspace:true}})}
                      >
                        Forgot Workspace?
                      </Typography>}
                      {/* <Typography color='primary' sx={{marginTop:'8px',fontFamily:'Inter',textDecoration:'underline',fontSize:12}}>Forgot Workspace?</Typography> */}
                    </Box>
                  </Box>
                  <LoginButton
                    type={"submit"}
                    fullWidth
                    disabled={loading}
                    sx={{ textAlign: "center" }}
                  >
                    Login to workspace
                  </LoginButton>

                  <Box
                    mt={3}
                    sx={{ borderBottom: "1px solid lightGrey" }}
                  ></Box>
                  <LoginButton
                    // type={"submit"}
                    onClick={() => navigate("/signup")}
                    // variant="outlined"
                    fullWidth
                    sx={{ textAlign: "center", marginBottom:'2rem' }}
                  >
                    Create workspace
                  </LoginButton>
                </Container>
              </form>
            )}
          </Formik>
        </Card>
      </Container>
        </Box>
      <Alert severity={severity} message={message} open={open} handleClose={handleClose} />
      </Page>
    </Root>
  );
};

const dispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  loginSuccess: (auth) => dispatch(loginSuccess(auth)),
  
});

export default connect(null, dispatchToProps)(SignIn);