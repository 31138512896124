import axios from "axios";
import { helpers } from "../_helpers";
const settings = require('../config');

const ApiEndpoint = settings.ApiEndPoint;

const apiEndpoint = ApiEndpoint;
export const uploadFiles = {
    awsStartUpload,
    azureStartUpload,
    getAwsUploadUrl,
    completeAwsUpload
}


async function awsStartUpload(params,settingId) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const data = await axios.post(`${apiEndpoint}/start-upload?setting_id=${settingId}`,params,requestOptions);
      return data;
    } catch (error) {
      return error.response;
    }
  }


async function azureStartUpload(params,settingId,progress) {
  helpers.setHeader();
  const requestOptions = {
    onUploadProgress:progress
  };
  try {
    const data = await axios.post(`${apiEndpoint}/start-upload?setting_id=${settingId}`,params,requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}

  async function getAwsUploadUrl(params,settingId) {
    helpers.setHeader();
  
    try {
      const data = await axios.post(`${apiEndpoint}/get-upload-url?setting_id=${settingId}`,params  );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async function completeAwsUpload(params,settingId) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const data = await axios.post(`${apiEndpoint}/complete-upload?setting_id=${settingId}`,params, requestOptions);
      return data;
    } catch (error) {
      return error.response;
    }
  }


  

  
  