

import React from 'react';

const Folder = (({theme, width})=>{
  return(
        <svg width={width  ? width:"44"} height={width  ? width:"45"} viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.33269 33.6667C3.18685 33.6667 2.2056 33.2583 1.38894 32.4417C0.572269 31.625 0.16463 30.6444 0.166019 29.5V4.49998C0.166019 3.35415 0.574353 2.3729 1.39102 1.55623C2.20769 0.739566 3.18824 0.331928 4.33269 0.333317H15.1139C15.6695 0.333317 16.1994 0.437483 16.7035 0.645817C17.2077 0.85415 17.6501 1.14929 18.0306 1.53123L20.9994 4.49998H37.666C38.8119 4.49998 39.7931 4.90832 40.6098 5.72498C41.4264 6.54165 41.8341 7.52221 41.8327 8.66665H8.49935C7.35352 8.66665 6.37227 9.07498 5.5556 9.89165C4.73894 10.7083 4.3313 11.6889 4.33269 12.8333V29.5L8.44727 15.8021C8.72505 14.8993 9.23755 14.1785 9.98477 13.6396C10.732 13.1007 11.5563 12.8319 12.4577 12.8333H39.3327C40.7563 12.8333 41.8764 13.3979 42.6931 14.5271C43.5098 15.6562 43.7264 16.8798 43.3431 18.1979L39.5931 30.6979C39.3153 31.6007 38.8028 32.3215 38.0556 32.8604C37.3084 33.3993 36.4841 33.668 35.5827 33.6667H4.33269Z" fill={theme ==='classic'? "#4CB5F5" : "#4CB5F5"}/>
        </svg>    
      );
})

export default Folder;