import { CloseOutlined } from "@mui/icons-material";
import { Box, Typography, Grid, Grow, IconButton, Tooltip } from "@mui/material";
import StyledBadge from "./../../components/Badge/index.jsx";
import Folder from "./../../images/home/folder.jsx";
import { FileType } from "../Function";
import React from "react";

const FilePreview = ({ data, setClick, setCurrentRow, setPreviewdata }) => {
  const fileType = (type) => {
    return FileType.getFileType(type);
  };
  const renderGrid = (label, name) => {
    return (
      <>
        <Grid item xs={12} md={6} mt={0.5}>
          <Typography variant="settingLable">{label} :</Typography>
        </Grid>
        <Grid item xs={12} md={6} mt={0.5} classes="label">
          <Tooltip title={name}>
          <Typography variant="settingLable">{name}</Typography>
          </Tooltip>
        </Grid>
      </>
    );
  };
  return (
    <Grid
      item
      xs={2.95}
      sx={{
        border: "1px solid lightGrey",
        borderRadius: "10px",
        margin: "8px 0",
      }}
    >
      {!data ? (
        <Typography m={2}>Loading...</Typography>
      ) : (
        <Grow in={true}>
          <Box sx={{ width: "100%", m: 1, p: 3 }}>
            <IconButton
              sx={{ float: "right", mt: -2 }}
              onClick={() => {
                setClick(false);
                setCurrentRow(false);
                setPreviewdata(false);
              }}
            >
              <CloseOutlined />
            </IconButton>
            <Box display="flex" mt={3} sx={{ minHeight: "150px" }}>
              {data.lock ? (
                <StyledBadge preview={true}>
                  {(data?.type === "png" ||
                    data?.type === "jpeg" ||
                    data?.type === "jpg" ||
                    data?.type === "webp") &&
                  data.url !== "" ? (
                    <img
                      style={{
                        width: 100,
                        height: "auto",
                        aspectRatio: 3 / 2,
                        objectFit: "contain",
                      }}
                      src={data?.url}
                      alt=""
                    />
                  ) : data?.type === "File Folder" ? (
                    <Folder theme={""} width={130} />
                  ) : (
                    <img src={fileType(data?.type)} width="100" alt="" />
                  )}
                </StyledBadge>
              ) : (data?.type === "png" ||
                  data?.type === "jpeg" ||
                  data?.type === "jpg" ||
                  data?.type === "webp") &&
                data.url !== "" ? (
                <img
                  style={{
                    width: 100,
                    height: "auto",
                    aspectRatio: 3 / 2,
                    objectFit: "contain",
                  }}
                  src={data?.url}
                  alt=""
                />
              ) : data?.type === "File Folder" ? (
                <Folder theme={""} width={130} />
              ) : (
                <img src={fileType(data?.type)} width="100" alt="" />
              )}
            </Box>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="previewHeader">Details</Typography>
              </Grid>
              {renderGrid("Name", data?.name)}
              {renderGrid("Type", data?.type?.toUpperCase())}
              {renderGrid("Size", data?.size)}
              {renderGrid("Storage Class", data?.properties?.StorageClass ? data?.properties?.StorageClass : data?.properties?.storageClass)}
              {renderGrid("Encryption Type", data?.properties?.ServerSideEncryption)}
            </Grid>
          </Box>
        </Grow>
      )}
    </Grid>
  );
};

export default FilePreview;
