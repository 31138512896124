import React, { useState, useEffect } from "react";
import TableComponent from "./favouriteTable";
import { Box } from "@mui/material";
import { apiData } from "../../_services/home";
import { useSelector } from "react-redux";
import Sidebar from "../../components/Toolbar/Sidebar.jsx";
import { crypto } from "../../components/Security/crypto.js";
import CreateDialog from "../../components/Dialog/CreateDialog.jsx";
import SessionExpired from "../../components/Dialog/SessionExpired.jsx";
import BackDrop from "../../components/Backdrop/backdrop.jsx";
import Alerts from "../../components/alert.jsx";
const Favourite = () => {
  const [action, setAction] = useState(false);
  const [data, setData] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [toastAlert, setToastAlert] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const state = useSelector((state) => state.authReducer);
  const [dialogTitle, setDialogTitle] = useState(false);
  const [open, setOpen] = useState(false);

  const GetFavouriteList = async () => {
    setOpen(true)
    try { 
    await  apiData.getFavouriteList(state.settingId).then((res) => {
      setOpen(false)
        if (res.status === 200) {
          const decreptedData = crypto.decryption(res?.data?.data);
          setData(decreptedData);
          console.log(decreptedData)
        } else if (res.status === 401) {
          setDialogTitle("Important Notice");
          setSessionExpired(true);
        } else if (res.status === 404) {
          setData([]);
          setToastMessage("No favourites found");
          setSeverity("success");
          setToastAlert(true);
        } else {
          setSeverity("error");
          setToastAlert(true);
          setToastMessage(res.data.message);
        }
      });
    } catch (error) {
      setSeverity("error");
      setToastAlert(true);
      setToastMessage('Something went wrong');
    }
  };

  useEffect(() => {
    GetFavouriteList();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const deleteFavourites = (id) => {
    // const data={
    // favourite_ids:selectedItems.length>=1 ? selectedItems :[id]
    // }
    setOpen(true)
    apiData.deleteFavourites(id).then((res) => {
      setAction(false);
      if (res.status === 200) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage("Removed from favourite list");
        GetFavouriteList();
      } else if (res.status === 401) {
        setDialogTitle("Important Notice");
        setSessionExpired(true);
      } else {
        // setLoading(false);
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  return (
    <Sidebar>
        <Box sx={{
          backgroundColor: "#fff",
          width: "100%",
          position: "relative",
          height: "calc(100vh - 100px)",
          overflow: "scroll",
          borderRadius:'20px',
          scrollbarWidth:'none'
        }}>
        <TableComponent
          data={data}
          deleteFavourites={deleteFavourites}
          action={action}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          GetFavouriteList={GetFavouriteList}
        />
        </Box>
        <Alerts
        severity={severity}
        handleClose={() => setToastAlert(false)}
        message={toastMessage}
        open={toastAlert}
      />
      <CreateDialog
        open={sessionExpired}
        title={dialogTitle}
        children={<SessionExpired />}
      />
      <BackDrop open={open} handleClose={handleClose} />
    </Sidebar>
  );
};

export default Favourite;
