
import React from 'react';

const Share = (({active})=>{
  return(
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke={active ? "#FFF" :"#404B5A"} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="m9.34 21h-3.34c-1.06087 0-2.07828-.4215-2.82843-1.1716-.75015-.7502-1.17157-1.7675-1.17157-2.8284v-10c0-1.06087.42142-2.07835 1.17157-2.82849.75015-.75015 1.76756-1.17151 2.82843-1.17151h2c.69805.00421 1.38284.19101 1.98633.54187.60347.35086 1.10467.85358 1.45367 1.45813.357.60635.8655 1.10947 1.4756 1.46008.61.35062 1.3008.5367 2.0044.53992h3.08c1.0609 0 2.0783.42136 2.8284 1.17151.7502.75014 1.1716 1.76762 1.1716 2.82849v1.5499"/><path d="m20 21c.663.0437 1.3163-.1777 1.8161-.6156s.8051-1.0565.8489-1.7195-.1777-1.3163-.6156-1.816c-.4379-.4998-1.0564-.8052-1.7194-.8489-.2351-.6683-.6991-1.232-1.3099-1.591-.6109-.3589-1.329-.4901-2.0273-.3703s-1.3316.4828-1.7879 1.0248c-.4562.542-.706 1.228-.7049 1.9365-.5304 0-1.0391.2107-1.4142.5858s-.5858.8838-.5858 1.4142.2107 1.0391.5858 1.4142c.3751.375.8838.5858 1.4142.5858z"/></g></svg>

      );
})

export default Share;
