

import React from 'react';

const CustomFolder = (({theme})=>{
  return(
 
<svg width="25" height="18" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.3156 1.79424H9.08907L7.65161 0.0883548C7.60326 0.0303156 7.53079 -0.00223361 7.45524 0.000119331H1.6249C0.73474 0.00658992 0.0166016 0.728943 0.0166016 1.61777V12.1099C0.0176816 13.0018 0.741515 13.7245 1.63472 13.7256H16.3156C17.2088 13.7245 17.9327 13.0018 17.9337 12.1099V3.40992C17.9327 2.51806 17.2088 1.79532 16.3156 1.79424Z" fill={theme ==='classic'? "#4CB5F5" : "#4CB5F5"}/>
<rect x="3.021" y="8.90991" width="6.00904" height="2" rx="1" fill="white"/>
</svg>
      );
})

export default CustomFolder;