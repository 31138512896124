

import React from 'react';

const ActiveHome = (({theme})=>{
  return(
    <svg width="389" height="289" viewBox="0 0 389 289" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M308.724 25.9297H182.407C178.57 25.942 174.816 24.8138 171.622 22.6885L149.095 7.62501C141.636 2.63692 132.86 -0.0174746 123.887 8.65763e-05H62.3932C50.3624 0.012956 38.8281 4.79785 30.3211 13.3049C21.8141 21.8119 17.0292 33.3462 17.0164 45.3769V64.8241H354.101C354.101 39.802 333.746 25.9297 308.724 25.9297Z" fill={ theme ==='classic'?"grey" : "grey"}/>
<path d="M330.378 288.999H58.5223C46.6159 288.986 35.1917 284.293 26.7137 275.933C18.2356 267.573 13.383 256.216 13.2023 244.311L0.124016 110.822V110.596C-0.304257 105.245 0.379478 99.8642 2.1322 94.7911C3.88492 89.7179 6.66868 85.0625 10.3083 81.1176C13.948 77.1728 18.3647 74.0239 23.2806 71.8692C28.1965 69.7146 33.5052 68.6007 38.8726 68.5977H350.109C355.475 68.6029 360.782 69.7184 365.696 71.8741C370.61 74.0298 375.024 77.179 378.662 81.1236C382.3 85.0682 385.083 89.7229 386.834 94.7949C388.586 99.867 389.269 105.247 388.841 110.596V110.822L375.698 244.311C375.517 256.216 370.665 267.573 362.187 275.933C353.709 284.293 342.284 288.986 330.378 288.999Z" fill={theme ==='classic'? "#0075ff" : "#0075ff"}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M185.265 146.454C189.426 139.502 199.512 139.502 203.678 146.454L235.85 200.12C240.139 207.276 234.986 216.375 226.638 216.375H162.305C153.958 216.375 148.804 207.276 153.093 200.12L185.265 146.454ZM194.472 162.693C195.539 162.693 196.564 163.117 197.319 163.872C198.074 164.627 198.498 165.651 198.498 166.719V182.824C198.498 183.892 198.074 184.916 197.319 185.671C196.564 186.426 195.539 186.85 194.472 186.85C193.404 186.85 192.38 186.426 191.625 185.671C190.87 184.916 190.445 183.892 190.445 182.824V166.719C190.445 165.651 190.87 164.627 191.625 163.872C192.38 163.117 193.404 162.693 194.472 162.693ZM199.84 197.587C199.84 199.01 199.274 200.376 198.268 201.383C197.261 202.389 195.895 202.955 194.472 202.955C193.048 202.955 191.682 202.389 190.676 201.383C189.669 200.376 189.103 199.01 189.103 197.587C189.103 196.163 189.669 194.797 190.676 193.791C191.682 192.784 193.048 192.218 194.472 192.218C195.895 192.218 197.261 192.784 198.268 193.791C199.274 194.797 199.84 196.163 199.84 197.587Z" fill="white"/>

</svg>
      );
})

export default ActiveHome;
