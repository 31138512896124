const customStyles = {
    rows: {
      style: {
        minHeight: "800px",
        cursor: "pointer",
        borderBottom: "1px solid #e1e5ea",
        // override the row height
      },
      selectedHighlightStyle: {
        "&:nth-of-type(n)": {},
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter",
        fontSize: "16px",
        lineHeight: "18px",
        fontWeight: "500",
        fontStyle: "regular",
        color: "#A7A7A7",
      },
    },
    headRow: {
      style: {
        borderBottomColor: "#EFEFEF",
        // paddingBottom: "12px",
        padding: "16px 0",
        backgroundColor: "#E1E5EA",
      },
    },
    headCells: {
      style: {
        // padding: "0px 32px",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "18px",
        letterSpacing: "0em",
        textAlign: "center",
        color: "#181D25",
      },
    },
    cells: {
      style: {
        // padding: "0px 32px",
        margin: "10px 0",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "18px",
        letterSpacing: "0em",
        textAlign: "center",
      },
    },
    pagination: {
      style: {
        float: "right",
        color:'#000',
      },
    },
  };

  export default customStyles