import React from 'react';

const Share = (({active})=>{
  return(

<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 9V7H15V5H17V3H19V5H21V7H19V9H17ZM5 21V3H14C13.6667 3.5 13.4167 3.97933 13.25 4.438C13.0833 4.89667 13 5.41733 13 6C13 7.2 13.379 8.25833 14.137 9.175C14.895 10.0917 15.8493 10.6667 17 10.9C17.3833 10.95 17.7167 10.975 18 10.975C18.2833 10.975 18.6167 10.95 19 10.9V21L12 18L5 21Z" fill={active ? "#FFF" :"#404B5A"}/>
</svg>
      );
})

export default Share;
