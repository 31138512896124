import React, { useEffect, useState, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  Stack,
  Typography,
  CircularProgress,
  Alert,
  Box,
  TextField,
  Breadcrumbs,
  Grid,
  InputBase,
} from "@mui/material";
import FilePreview from "../../components/Card/FilePreview";
import FilePath from "./../../images/filePath.svg";
import AddFile from "./../../images/addFile.svg";
import AddFolder from "./../../images/addFolder.svg";
import Copy from "./../../images/copy.svg";
import Sidebar from "./../../components/Toolbar/Sidebar";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import CreateDialog from "../../components/Dialog/CreateDialog";
import Move from "./../../images/move.svg";
import Upload from "./../../images/upload.svg";
// import Download from "./../../images/download.svg";
import Delete from "./../../images/delete.svg";
import Archive from "./../../images/archieve.svg";
import HomeIcon from "./../../images/home.svg";
import Back from "./../../images/back.svg";
import Next from "./../../images/next.svg";
import Up from "./../../images/Up.svg";
import Cookies from "universal-cookie";
import Refresh from "./../../images/refresh.svg";
import SearchIcon from "./../../images/search.svg";
import TableComponent from "./FolderTable";
import { apiData } from "../../_services/home";
import SessionExpired from "../../components/Dialog/SessionExpired";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setFiles,
  setProgress,
  logoutSuccess,
  setSettingId,
  setPassword,
} from "../../store/bugs";
import { connect } from "react-redux";
import UploadDialog from "../../components/Dialog/UploadDialog";
import axios from "axios";
import DialogInfo from "./../../images/dialogInfo.svg";
import { MUI } from "../../components/CustomComponents";
import Edit from "./../../images/edit.svg";
import Alerts from "../../components/alert";
import Page from "../../components/page";
import CreateUser from "./CreateFolder";
import FullDialog from "../../components/Dialog/fullDialog";
import { settingsData } from "../../_services/settings";
import { crypto } from "../../components/Security/crypto";
import { uploadFiles } from "../../_services/upload";
import Confirmation from "../../components/Dialog/Confirmation";

const IconBtnSmallLeft = styled.img`
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`;
const TextSm = styled.div`
  position: relative;
  white-space: nowrap;
`;
const IconBtnSmallRight = styled.img`
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const Button = styled.div`

  border-radius: var(--numbers-radius-sm);
  height: 32px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-7xs);
  border
`;
const IconHugeviewGrid1 = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`;

const ButtonParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const ToolbarChild = styled.div`
  position: relative;
  border-right: 1px solid var(--layout-border-intense);
  box-sizing: border-box;
  width: 1px;
  height: 36px;
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Toolbar1 = styled.div`
  overflow: scroll;
  scrollbar-width: none;
  background-color: var(--neutral-neutral-0);
  border-bottom: 1px solid var(--layout-border);
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 11px var(--padding-5xs);
  gap: 16px;
  font-size: var(--font-size-sm);
`;

const IconBtnGroupSmall = styled.img`
  position: relative;
  width: 18px;
  height: 18px;
  display: none;
`;
const Button11 = styled.div`
  cursor: pointer;
  border-radius: var(--numbers-radius-sm);
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08),
      rgba(102, 102, 102, 0.05)
    ),
    #fff;
  width: 100px;
  border: 1px solid var(--layout-border);
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-3xs);
  gap: var(--gap-7xs);
`;
const IconButton = styled.button`
  border-radius: var(--numbers-radius-sm);
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08),
      rgba(102, 102, 102, 0.05)
    ),
    #fff;
  border: 1px solid var(--layout-border);
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: var(--padding-5xs) var(--padding-7xs);
`;
const LabelInput = styled.div`
  flex: 1;
  position: relative;
`;
const FormLabel = styled.div`
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 0px var(--padding-7xs);
`;
const Placeholder = styled.div`
  flex: 1;
  position: relative;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const InputLeft = styled.div`
  flex: 1;
  border-radius: var(--numbers-radius-sm) 0 0 var(--br-7xs);
  background-color: var(--neutral-neutral-0);
  border: 1px solid var(--layout-border);
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-5xs);
  gap: var(--gap-5xs);
`;
const Text17 = styled.div`
  position: relative;
  font-weight: 500;
  display: none;
`;
const ButtonGroup1 = styled.div`
  border-radius: 0 var(--numbers-radius-sm) var(--br-7xs) 0;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08),
      rgba(102, 102, 102, 0.05)
    ),
    #fff;
  border: 1px solid var(--layout-border);
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  gap: var(--gap-7xs);
  margin-left: -1px;
`;
const InputElement = styled.div`
  align-self: stretch;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Input = styled.div`
  width: calc(100vw - 380px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 110px;
  width: 100%;
  color: var(--text-colors-text-secondary);
`;

const InputElement1 = styled.div`
  align-self: stretch;
  border-radius: var(--numbers-radius-sm);
  background-color: var(--neutral-neutral-0);
  border: 1px solid var(--layout-border);
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-5xs);
  gap: var(--gap-5xs);
`;
const Input1 = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--text-colors-text-muted);
`;
const FrameDiv = styled.div`
  position: absolute;
  top: 48px;
  overflow-x: scroll;
  scrollbar-width: none;
  background-color: var(--neutral-neutral-0);
  border-bottom: 1px solid var(--layout-border);
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-xs);
  gap: var(--gap-5xs);
  font-size: var(--font-size-sm);
`;

const Home = (props) => {
  const cookies = new Cookies();
  const myBucket = cookies.get("bucketName");
  const location = useLocation();
  const [severity, setSeverity] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Important Notice");
  const [sessionExpired, setSessionExpired] = useState(false);
  const [click, setClick] = useState(false);
  const [directory, setDirectory] = useState(location?.state ? true : false);
  const [editorMode, setEditorMode] = useState(false);
  const [previewdata, setPreviewdata] = useState();
  const [handleConfirm, setHandleConfirm] = useState(false);

  const [bucket, setBucket] = useState(myBucket ? false : true);
  const [paste, setPaste] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [folderPath, setFolderPath] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [actionType, setActionType] = useState(false);
  const [lock, setLock] = React.useState(false);
  const [activeObject, setActiveObject] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = React.useState([]);
  const [previousPath, setPreviousPath] = useState(false);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(false);
  const [progressMap, setProgressMap] = useState([]);
  const [currentSetting, setCurrentSetting] = useState(null);
  const [editPath, setEditPath] = useState(false);
  const [newPath, setNewPath] = useState(null);

  const [currentRow, setCurrentRow] = useState(false);

  const [initialData, setInitialData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { CustomSelect } = MUI;
  const globalState = props.authReducer;
  const password = props.authReducer.password;
  const path = location?.state?.path
    ? location?.state?.path
    : localStorage.getItem("path") !== "null"
    ? localStorage.getItem("path")
    : "";
  const navigate = useNavigate();

  useEffect(() => {
    // GetBucketList();
    if (path) {
      setFolderPath(path);
    }
    getDirectoryList(path);
    // getDirectoryList();
    GetBucketList();
  }, []);

  const setType = (type) => {
    if (type === "rename") {
      setOpenDialog(true);
    } else {
      setClick(false);
      // setSelectedItems([]);
      if (actionType === type) {
        setActionType();
        setSelectedItems([]);
      } else {
        setActionType(type);
      }
    }
  };

  const createFile = (values) => {
    setOpenDialog(false);
    setOpenPreview(true);
    setEditorMode("edit");
    setActiveObject({
      prefix: null,
      newFilePath: `${folderPath}${values.title}`,
      title: values.title,
    });
  };

  const breadcrumbs = [
    // <Link
    //   key={2}
    //   underline="none"
    //   variant="folderSize"
    //   sx={{
    //     display: "flex",
    //     color: "#7C7C7C",
    //     alignItems: "center",
    //     cursor: "pointer",
    //   }}
    //   color="inherit"
    //   onClick={() => {
    //     if (location.pathname === "/home") {
    //       navigate("/home");
    //       props.setBucket(true);
    //       props.setPrefix();
    //       props.setFolderPath("");
    //       cookies.remove("bucketName");
    //     } else {
    //       navigate(location.pathname);
    //     }
    //   }}
    // >
    //   Home
    // </Link>,
  ];

  const handleEditPath = () => {
    const path = newPath
      ? newPath.endsWith("/")
        ? newPath
        : `${newPath}/`
      : "";
    // setPrefix(path)
    setFolderPath(path);
    setEditPath(false);
    getDirectoryList(path);
  };

  let url = folderPath;
  const root = url ? url?.split("/").filter((item) => item) : [];

  if (root) {
    root.map((item, index) => {
      return breadcrumbs.push(
        <Typography
          onClick={() => {
            if (index !== root.length - 1) {
              let trimmed_string = folderPath
                ? folderPath?.split(item)[0] + item
                : item;
              getDirectoryList(trimmed_string ? `${trimmed_string}/` : "");
              setFolderPath(`${trimmed_string}/`);
            }
          }}
          variant="folderSize"
          sx={{
            display: "flex",
            color: "#7C7C7C",
            alignItems: "center",
            cursor: "pointer",
          }}
          color="text.primary"
        >
          {item}
        </Typography>
      );
    });
  }

  const [options, setOptions] = useState([]);
  const GetBucketList = async () => {
    const group = true;
    try {
      await settingsData.getAllUserSettings(group).then((res) => {
        // setLoading(false);

        if (res.status === 200) {
          const decreptedData = crypto.decryption(res.data?.data);
          let array = decreptedData.map((item) => {
            return {
              ...item,
              cloud_name:
                item.cloud_provider.name === "Microsoft Azure Blob Storage"
                  ? "Microsoft Azure"
                  : item.cloud_provider.name,
            };
          });
          const sortedArrayDesc = array.sort(
            (a, b) => a.cloud_provider_id - b.cloud_provider_id
          );
          setOptions(sortedArrayDesc);
          let setting = array.find((item) => item.id === globalState.settingId);
          setCurrentSetting(setting);
        } else if (res.status === 401 || res.status === 403) {
          props.logoutSuccess();
          navigate("/signin", { replace: true });
          setDialogTitle("Important Notice");
          setSessionExpired(true);
        } else {
          setSeverity("error");
          setToastAlert(true);
          setToastMessage(res.data.error);
        }
      });
    } catch (error) {
      setSeverity("error");
      setToastAlert(true);
      setToastMessage("Something went wrong");
    }
  };

  const handleDelete = (values) => {
    const data = {
      setting_id: globalState.settingId,
      source: selectedItems.length >= 1 ? selectedItems : [values],
    };
    setHandleConfirm(false)
    setActionType();
    setPaste();
    apiData.deleteObject(data, myBucket).then((res) => {
      setSelectedItems([]);
      if (res.data.status) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage(res.data.message);
        // setPrefix()
        setClick(false);
        setTimeout(() => {
          getDirectoryList(folderPath);
        }, 500);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };
  const handleCopy = (values, setting_id) => {
    const source = selectedItems.length >= 1 ? selectedItems : [values];
    const copyFolderPath = getCurrentPath(
      source[0].endsWith("/") ? source[0].slice(0, -1) : source[0]
    );
    const files = source.map((item) => {
      return {
        name: item,
        size: 0,
        type: "Copy",
      };
    });
    const fileList =
      globalState.files.length > 0
        ? Array.from(files).concat(globalState.files)
        : files;
    props.setFiles({ files: fileList });

    setActionType();
    setPaste();
    source.forEach((item)=>{
    const data = {
      source: [item],
      destination: folderPath ? `${folderPath.slice(0, -1)}` : "/",
      current_prefix: copyFolderPath ? `${copyFolderPath}/` : "/",
      destination_setting_id: globalState.settingId,
      source_setting_id: setting_id,
    };
    setProgressMap((prevProgressMap) => ({
      ...prevProgressMap,
      [item]: 10,
    }));
      apiData.copyObject(data).then((res) => {
        setSelectedItems([]);
        if (res.data.status) {
          setProgressMap((prevProgressMap) => ({
            ...prevProgressMap,
            [item]: 100,
          }));
          enqueueSnackbar(`Copied ${item}`, { 
            variant: 'success'
          });
          // setSeverity("success");
          // setToastAlert(true);
          // setToastMessage(res.data.message);
          // setPrefix()
          setClick(false);
          setTimeout(() => {
            // getDirectoryList(folderPath);
          }, 500);
        } else {
          setSeverity("error");
          setToastAlert(true);
          setToastMessage(res.data.message ? res.data.message : res.data.error);
        }
      });
    })
  };
  const handleClickVariant = (variant) => () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('This is a success message!', { variant });
  };

  // console.log(globalState)
  const movetoTash = (values) => {
    const source = selectedItems.length >= 1 ? selectedItems : [values];
    const copyFolderPath = getCurrentPath(
      source[0].endsWith("/") ? source[0].slice(0, -1) : source[0]
    );
    const data = {
      path: selectedItems.length >= 1 ? selectedItems : [values],
      bucket: myBucket,
      current_prefix: `${copyFolderPath}/`,
      setting_id: globalState.settingId,
    };
    setActionType();
    setPaste();
    apiData.moveToTrash(data, myBucket).then((res) => {
      setSelectedItems([]);
      if (res.data.status) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage(res.data.message);
        // setPrefix()
        setClick(false);
        setTimeout(() => {
          getDirectoryList(folderPath);
        }, 200);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  const createFolder = (values) => {
    const data = {
      prefix: folderPath ? folderPath : "",
      folder_name: values.title,
    };
    apiData.createFolder(data, globalState.settingId).then((res) => {
      setOpenDialog(false);
      setLoading(false);
      if (res.data.status) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage(res.data.message);
        setBucket(false);
        setTimeout(() => {
          getDirectoryList(folderPath);
        }, 500);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  const LockObject = (values) => {
    const lockObject = {
      path: lock.location,
      password: values.title,
      setting_id: globalState.settingId,
    };
    const unlockObject = {
      id: lock.id,
      password: values.title,
    };
    const data = dialogTitle === "Unlock" ? unlockObject : lockObject;
    apiData.LockObject(data, dialogTitle.toLowerCase()).then((res) => {
      if (res.data.status) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage(res.data.message);
        setOpenDialog(false);
        setLock(false);
        // setPrefix()
        setClick(false);
        setTimeout(() => {
          getDirectoryList(folderPath);
        }, 500);
      } else {
        setOpenDialog(false);
        setLock(false);
        setSeverity("error");
        setToastAlert(true);
        setLock(false);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  const addToFavourite = (path) => {
    const data = {
      setting_id: globalState.settingId,
      path: path,
    };
    apiData.addFavorite(data).then((res) => {
      if (res.status === 200) {
        setSeverity("success");
        setToastAlert(true);
        getDirectoryList(folderPath);
        setToastMessage("Added to favourite list");
      } else if (res.status === 401) {
        setDialogTitle("Important Notice");
        setSessionExpired(true);
      } else {
        // setLoading(false);
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  const deleteFavourite = (id) => {
    apiData.deleteFavourites(id).then((res) => {
      if (res.status === 200) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage("Removed from favourite list");
        getDirectoryList(folderPath);
      } else if (res.status === 401) {
        setDialogTitle("Important Notice");
        setSessionExpired(true);
      } else {
        // setLoading(false);
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  useEffect(() => {
    if (item?.id) {
      deleteFavourite(item.id);
    } else if (item) {
      addToFavourite(item);
    }
  }, [item]);

  const handleMove = (values, setting_id) => {
    const source = selectedItems.length >= 1 ? selectedItems : [values];
    const copyFolderPath = getCurrentPath(
      source[0]?.endsWith("/") ? source[0].slice(0, -1) : source[0]
    );
    const files = source.map((item) => {
      return {
        name: item,
        size: 0,
        type: "Move",
      };
    });
    const fileList =
      globalState.files.length > 0
        ? Array.from(files).concat(globalState.files)
        : files;
    props.setFiles({ files: fileList });

    setActionType();
    setPaste();
    source.forEach((item)=>{
    const data = {
      source: [item],
      destination: folderPath ? `${folderPath.slice(0, -1)}` : "/",
      current_prefix: copyFolderPath ? `${copyFolderPath}/` : "/",
      destination_setting_id: globalState.settingId,
      source_setting_id: setting_id,
    };
    setActionType();
    setPaste();
  
      setProgressMap((prevProgressMap) => ({
        ...prevProgressMap,
        [item]: 20,
      }));
    apiData.moveObject(data).then((res) => {
      setSelectedItems([]);
      if (res.data.status) {
        setProgressMap((prevProgressMap) => ({
          ...prevProgressMap,
          [item]: 100,
        }));
        enqueueSnackbar(`Moved ${item}`, { 
          variant: 'success'
        });        
        // setSeverity("success");
        // setToastAlert(true);
        // setToastMessage(res.data.message);
        // setPrefix()
        setClick(false);
        setTimeout(() => {
          // getDirectoryList(folderPath);
        }, 500);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  })
  };

  let chunkSize = 5 * 1024 * 1024;

  const setting_id = globalState.settingId;
  const handleUpload = async (file, path) => {
    if (!file) return;
    try {
      const startParams = {
        fileName: path.file,
        fileType: file.type,
      };
      const response = await uploadFiles.awsStartUpload(
        startParams,
        setting_id
      );
      const decreptedDataForUploadId = crypto.decryption(response.data.data);
      let { uploadId, fileType } = decreptedDataForUploadId;
      if (!uploadId) {
        console.error("Upload ID is missing or invalid.");
        return;
      }
      let totalSize = file.size;
      // set chunk size to 10MB
      // calculate number of chunks
      let numChunks = Math.ceil(totalSize / chunkSize);

      // generate presigned urls

      const getParamsforUrl = {
        fileName: path.file,
        uploadId: uploadId,
        partNumber: numChunks,
      };
      const presignedUrls_response = await uploadFiles.getAwsUploadUrl(
        getParamsforUrl,
        setting_id
      );
      const decreptedData = crypto.decryption(presignedUrls_response.data.data);
      let parts = [];
      const uploadPromises = [];
      let totalProgress = 0;

      for (let i = 0; i < numChunks; i++) {
        let start = i * chunkSize;
        let end = Math.min(start + chunkSize, totalSize);
        let chunk = file.slice(start, end);
        let presignedUrl = decreptedData[i];
        delete axios.defaults.headers.common["Authorization"];
        uploadPromises.push(
          axios
            .put(presignedUrl, chunk, {
              headers: {
                "Content-Type": fileType,
              },
              // eslint-disable-next-line no-loop-func
            })
            .then((response) => {
              if (response.status === 200) {
                totalProgress += 1;
                const percentCompleted = Math.round(
                  (totalProgress / numChunks) * 100
                );
                setProgressMap((prevProgressMap) => ({
                  ...prevProgressMap,
                  [file.name]: percentCompleted,
                }));
              }
              return response;
            })
        );
      }

      const uploadResponses = await Promise.all(uploadPromises);
      uploadResponses.forEach((response, i) => {
        parts.push({
          etag: response.headers.etag,
          PartNumber: i + 1,
        });
      });
      const params = {
        fileName: path.file,
        uploadId: uploadId,
        parts: parts,
      };
      // make a call to multipart complete api
      let complete_upload = await uploadFiles.completeAwsUpload(
        params,
        setting_id
      );
      // if upload is successful, alert user
      if (complete_upload.status === 200) {
        enqueueSnackbar(`Uploaded ${file.name}`, { 
          variant: 'success'
        });
        setTimeout(() => {
          setOpen(false);
          // getDirectoryList(folderPath);
        }, 1500);
      } else {
        console.log("Upload failed.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleFileUploadForGCP(file, pathDetails) {
    try {
      const uploadPromises = [];
      const chunkSize = 10 * 1024 * 1024; // 5MB
      const totalChunks = Math.ceil(file.size / chunkSize);

      const payload = {
        fileName: pathDetails.file,
        metadata: {
          "Content-Type": file.type,
        },
      };
      const response = await uploadFiles.awsStartUpload(payload, setting_id);
      const decreptedDataForUploadId = crypto.decryption(response.data.data);
      let totalProgress = 0;
      const uploadChunk = async (chunk, startByte, endByte) => {
        try {
          await axios.put(decreptedDataForUploadId, chunk, {
            headers: {
              "Content-Type": file.type,
              "Content-Range": `bytes ${startByte}-${endByte - 1}/${file.size}`,
            },
          });
        } catch (error) {
          console.error("Error uploading chunk:", error);
        }
      };

      for (let i = 0; i < totalChunks; i++) {
        const startByte = i * chunkSize;
        const endByte = Math.min(startByte + chunkSize, file.size);
        const chunk = file.slice(startByte, endByte);
        totalProgress += 1;
        const percentCompleted = Math.round(
          (totalProgress / totalChunks) * 100
        );
        setProgressMap((prevProgressMap) => ({
          ...prevProgressMap,
          [file.name]: percentCompleted,
        }));
        await uploadChunk(chunk, startByte, endByte, i);
      }

      await Promise.all(uploadPromises);
      // Step 1: Request the upload URL from the backend
      setTimeout(() => {
        setOpen(false);
        getDirectoryList(folderPath);
      }, 1500);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  // const startRef = useRef(0);
  // const endRef = useRef(chunkSize);
  // const uploadedChunksRef = useRef(0);

  const uploadFileForAzure = async (file, path) => {
    const partSize = 5 * 1024 * 1024; // 5MB per part
    const totalParts = Math.ceil(file.size / partSize);

    try {
      // Call the backend API to start the upload and get block IDs and URLs
      const response = await uploadFiles.awsStartUpload(
        {
          fileName: path.file,
          fileType: file.type,
          partNumber: totalParts,
        },
        setting_id
      );
      const { presignedUrls, fileType } = crypto.decryption(response.data.data);
      const uploadPromises = presignedUrls.map(async (part, index) => {
        const start = index * partSize;
        const end = Math.min(start + partSize, file.size);
        const blobPart = file.slice(start, end);
        delete axios.defaults.headers.common["Authorization"];
        return axios.put(part.url, blobPart, {
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "x-ms-version": "2020-04-08",
            "Content-Type": fileType,
            "x-ms-blob-content-md5": "", // Optionally include MD5 checksum
          },
          params: {
            blockid: part.blockId,
          },
          onUploadProgress: (progressEvent) => {
            // const progress = ((index + 1) / totalParts) * 100;
            // setUploadProgress(progress.toFixed(2));
          },
        });
      });

      await Promise.all(uploadPromises);
      const completeResponse = await uploadFiles.completeAwsUpload(
        {
          fileName: path.file,
          parts: presignedUrls.map((part, index) => ({
            blockId: part.blockId,
            etag: "", // Placeholder for ETag if required, can be retrieved from the PUT response if needed
          })),
        },
        setting_id
      );
      if (completeResponse.status === 200) {
        setTimeout(() => {
          setOpen(false);
          getDirectoryList(folderPath);
        }, 1500);
      }
    } catch {}
  };
  const UploadDocuments = async (files) => {
    try {
      if (files.length >= 1 && files.length <= 10) {
        let array1 = Array.from(files);
        setFile([...file, ...array1]);
        const fileNameList = Array.from(files).map((item) => {
          return {
            name: item.name,
            size: item.size,
            type: "Upload",
          };
        });
        const fileList =
          globalState.files.length > 0
            ? Array.from(fileNameList).concat(globalState.files)
            : fileNameList;
        props.setFiles({ files: fileList });
        array1.forEach(async (item, index) => {
          const currentFile = item;
          const pathDetails = {
            file: `${folderPath}${item.name}`,
          };
          const cloudAccount = options.filter(
            (item) => item.id === globalState.settingId
          );
          const cloudId = cloudAccount && cloudAccount[0].cloud_provider_id;
          if (currentFile.size < 10000000 || cloudId !== 1) {
            try {
              const response = await apiData.uploadFiles(
                pathDetails,
                globalState.settingId,
                "upload_object"
              );
              if (response.status === 200) {
                try {
                  const decreptedData = crypto.decryption(response.data.data);
                  const url = decreptedData.url;
                  const type = decreptedData.file_type;
                  await delete axios.defaults.headers.common["Authorization"];
                  axios
                    .put(url, currentFile, {
                      headers: {
                        "Content-Type": type,
                        "x-ms-blob-type": "BlockBlob",
                      },
                      onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgressMap((prevProgressMap) => ({
                          ...prevProgressMap,
                          [currentFile.name]: percentCompleted,
                        }));
                      },
                    })
                    .then((res) => {
                      if (res.status === 200 || res.status === 201) {
                        if (index === files.length - 1) {
                          enqueueSnackbar(`Uploaded ${currentFile.name}`, { 
                            variant: 'success'
                          });
                          setTimeout(() => {
                            setOpen(false);
                            // getDirectoryList(folderPath);
                          }, 1500);
                        }
                      } else {
                        console.error(`Error uploading file ${file.name}:`);
                      }
                    });
                } catch (error) {
                  console.log(error);
                }
              }
            } catch (error) {
              setSeverity("error");
              setToastAlert(true);
              setToastMessage("Error while uploading files");
              console.error(`Error uploading file ${file.name}:`, error);
            }
          } else {
            handleUpload(item, pathDetails);
          }
        });
      } else {
        setFile(Array.from(files));
      }
    } catch (error) {
      setSeverity("error");
      setToastAlert(true);
      setToastMessage("Error while uploading");
    }
  };

  const handleFileUpload = () => {
    setOpen(true);
    setFile([]);
    setDialogTitle("Upload");
    setActionType();
    setSelectedItems([]);
  };

  function moveObjectToFront(array, key, value) {
    const index = array.findIndex((obj) => obj[key] === value);
    if (index > -1) {
      const [item] = array.splice(index, 1); // Remove the object from its current position
      array.unshift(item);
    }
    return array;
  }

  const getDirectoryList = (value, settingId, filePassword, search) => {
    if (filePassword) {
      props.setPassword({ password: filePassword });
    }
    navigate('.', { replace: true, state: null });
    setCurrentRow(false)
    const NewPassword = filePassword ? filePassword : password;
    const prefixValue = !value ? "" : value;
    setClick(false);
    setLoading(true);
    setData([]);
    setEditPath(false);
    apiData
      .getDirectoryList(
        prefixValue,
        settingId ? settingId : globalState.settingId,
        NewPassword,
        (search || search ==='') ?search : searchText
      )
      .then((res) => {
        setPreviewdata();
        if (res.status === 200) {
          setFolderPath(value);
          localStorage.setItem("path", prefixValue);
          let decreptedData = crypto.decryption(res?.data?.data);
          setLoading(false);
          setFile([]);
          if (location.state?.item) {
            let favouriteItem = decreptedData.filter(
              (item) => item.prefix === location.state?.item
            );
            setCurrentRow(favouriteItem[0]);
            decreptedData = moveObjectToFront(
              decreptedData,
              "prefix",
              location.state?.item
            );
            getObjectDetails(location.state?.item);
          }
          setData(decreptedData);
          // setMoreData(res.data.response.slice(0, 50));
        } else if (res.status === 401) {
          if (res.data.message === "Invalid Password!") {
            setSeverity("error");
            setToastAlert(true);
            setData(data);
            setLoading(false);
            setToastMessage(res.data.message);
          } else {
            setDialogTitle("Important Notice");
            setSessionExpired(true);
          }
        } else {
          // props.setPassword({password:null})
          setLoading(false);
          setSeverity("error");
          setToastAlert(true);
          setToastMessage(res.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const renameFile = (values) => {
    let text = values.location;
    let type = values.location.endsWith("/") ? true : false;
    const renamePath = getCurrentPath(
      values.location.endsWith("/")
        ? values.location.slice(0, -1)
        : values.location
    );
    let lastSlashIndex = text.lastIndexOf("/");
    if (lastSlashIndex !== -1) {
      text = text.slice(0, lastSlashIndex);
    } else {
      text = "";
    }
    const data = {
      old_name: values.location,
      new_name:
        text === ""
          ? values.title
          : `${renamePath}${renamePath ? "/" : ""}${values.title}${type ? "/" : ""}`,
      setting_id: globalState.settingId,
    };
    setSeverity("success");
    setToastAlert(true);
    setToastMessage("Will be renamed shortly");
    apiData.renameObject(data).then((res) => {
      if (res.data.status) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage(res.data.message);
        setClick(false);
        setOpenDialog(false);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  function getCurrentPath(string) {
    return string.substring(0, string.lastIndexOf("/"));
  }
  // useEffect(() => {
  //  getDirectoryList(prefix)
  // }, [prefix]);

  const getDirectoryDetails = (prefix) => {
    setClick(true);
    setPreviewdata(null);
    apiData.getDirectoryDetails(globalState.settingId, prefix).then((res) => {
      if (res.status === 200) {
        const decreptedData = crypto.decryption(res.data.data);
        setPreviewdata(decreptedData);
      } else if (res.status === 401) {
        setDialogTitle("Important Notice");
        setSessionExpired(true);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  const getObjectDetails = (prefix) => {
    setClick(true);
    setPreviewdata(false);
    apiData
      .getObjectDetails(
        globalState.settingId,
        prefix ? prefix : prefix,
        password
      )
      .then((res) => {
        if (res.status === 200) {
          const decreptedData = crypto.decryption(res?.data?.data);
          setPreviewdata(decreptedData);
        } else if (res.status === 401) {
          if (res.data.message === "Invalid Password!") {
            setSeverity("error");
            setToastAlert(true);
            // props.setPassword({password:null})
            setToastMessage(res.data.message);
          } else {
            setDialogTitle("Important Notice");
            setSessionExpired(true);
          }
        } else {
          setSeverity("error");
          setToastAlert(true);
          setToastMessage(res.data.message ? res.data.message : res.data.error);
        }
      });
  };

  const handleRootDirectory = () => {
    setPreviousPath(folderPath);
    setBucket(true);
    GetBucketList();
    setFolderPath("");
    getDirectoryList();
    cookies.remove("bucketName");
  };

  useEffect(() => {
    if (Object.keys(progressMap).length !== 0) {
      props.setProgress({ progress: progressMap });
    }
  }, [progressMap]);

  const handleBack = () => {
    setPreviousPath(folderPath);
    if (folderPath?.endsWith("/")) {
      // Remove the string after the last 2nd '/'
      let parts = folderPath.split("/");
      let trimmedStr = parts.slice(0, -2).join("/") + "/";
      let path = trimmedStr === "/" ? "" : trimmedStr;
      setFolderPath(path);
      getDirectoryList(path);
    } else {
      // Remove the string after the last '/'
      let lastIndex = folderPath.lastIndexOf("/");
      let trimmedStr = folderPath.substring(0, lastIndex + 1);
      setFolderPath(trimmedStr);
      getDirectoryList(trimmedStr);
    }
  };

  const handleNewFile = () => {
    setOpenDialog(true);
    setDialogTitle("Create New File");
  };

  const [timer, setTimer] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(()=>{
    if(searchText){
         getDirectoryList(folderPath,"","",searchText); // Use the latest searchText
    }else{
      getDirectoryList(path,"","",''); // Use the latest searchText 
    }
  },[searchText])

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    if (e.target.value) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        setData([]);
        setSearchText(searchValue);
      }, 500);
      setTimer(newTimer);
    } else {
      setSearchText('');
    }
  };

  const handleArchieve = (values) => {
    const source = selectedItems.length >= 1 ? selectedItems : [selectedItems];
    // const path = getCurrentPath(
    //   source[0]?.endsWith("/") ? source[0].slice(0, -1) : source[0]
    // );
    const data = {
      zip_name: `${values.title}.zip`,
      setting_id: globalState.settingId,
      prefix: source,
      destination: folderPath,
      current_prefix: folderPath,
    };
    apiData.archiveFiles(data).then((res) => {
      setPaste();
      setActionType();
      setSelectedItems([]);
      if (res.data.status) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage(res.data.message);
        setClick(false);
        setOpenDialog(false);
        setTimeout(() => {
          getDirectoryList(folderPath);
        }, 1500);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  const handleUnzip = (values) => {
    const data = {
      setting_id: globalState.settingId,
      zip_name: values,
      destination: folderPath,
    };
    apiData.unzipFile(data).then((res) => {
      setActionType();
      setSelectedItems([]);
      setPaste();
      if (res.data.status) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage(res.data.message);
        setClick(false);
        setOpenDialog(false);
        setTimeout(() => {
          getDirectoryList(folderPath);
        }, 1500);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  useMemo(() => {
    if (lock) {
      setOpenDialog(true);
      setDialogTitle(lock.type);
    }
  }, [lock]);

  const handlePath = () => {
    setEditPath(!editPath);
    setNewPath(folderPath);
  };

  const handleFolderPath = (e) => {
    setNewPath(e.target.value);
  };

  return (
    <Page>
      <SnackbarProvider maxSnack={3} autoHideDuration= {5000}  />
      <Sidebar>
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            position: "relative",
            height:
              actionType || paste
                ? "calc(100vh - 200px)"
                : "calc(100vh - 100px)",
            overflow: "scroll",
            scrollbarWidth: "none",
            borderRadius: "20px",
          }}
        >
          {/* <Toolbar /> */}
          <Toolbar1>
            <ButtonParent>
              <ButtonParent>
                <Button onClick={() => handleNewFile()}>
                  <IconBtnSmallLeft alt="" src={AddFile} />
                  <TextSm>Add file</TextSm>
                  <IconBtnSmallRight alt="" />
                </Button>
                <Button
                  onClick={() => {
                    setOpenDialog(true);
                    setDialogTitle("Create Folder");
                  }}
                >
                  <IconBtnSmallLeft alt="" src={AddFolder} />
                  <TextSm>Add folder</TextSm>
                  <IconBtnSmallRight alt="" />
                </Button>
                <Button onClick={handleFileUpload}>
                  <IconBtnSmallLeft alt="" src={Upload} />
                  <TextSm>Upload</TextSm>
                  <IconBtnSmallRight alt="" />
                </Button>
              </ButtonParent>
              <ToolbarChild />
              <ButtonGroup>
                <Button onClick={() => setType("copy")}>
                  <IconBtnSmallLeft alt="" src={Copy} />
                  <TextSm>Copy</TextSm>
                  <IconBtnSmallRight alt="" />
                </Button>
                <Button onClick={() => setType("move")}>
                  <IconBtnSmallLeft alt="" src={Move} />
                  <TextSm>Move</TextSm>
                  <IconBtnSmallRight alt="" />
                </Button>
                <Button onClick={() => setType("delete")}>
                  <IconBtnSmallLeft alt="" src={Delete} />
                  <TextSm>Delete</TextSm>
                  <IconBtnSmallRight alt="" />
                </Button>
              </ButtonGroup>
              {/* <ToolbarChild /> */}
              {/* <ButtonParent>
                <Button onClick={() => setType("archieve")}>
                  <IconBtnSmallLeft alt="" src={Archive} />
                  <TextSm>Archive</TextSm>
                  <IconBtnSmallRight alt="" />
                </Button>
              </ButtonParent> */}
            </ButtonParent>
            <CustomSelect
              disableClearable
              id="grouped-demo"
              size="small"
              value={currentSetting}
              options={options}
              onChange={(e, value) => {
                if (value) {
                  props.setSettingId({ settingId: value.id });
                  getDirectoryList("", value.id);
                  setFolderPath("");
                  setCurrentSetting(value);
                }
              }}
              groupBy={(option) => option.cloud_name}
              getOptionLabel={(option) => option.setting_name}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "200px" }}
                  {...params}
                  label="Switch Account"
                />
              )}
            />
          </Toolbar1>

          <FrameDiv>
            <Button11 onClick={handleRootDirectory}>
              <IconBtnSmallLeft alt="" src={HomeIcon} />
              <TextSm>Main</TextSm>
              <IconBtnGroupSmall alt="" />
            </Button11>
            <IconButton>
              <IconHugeviewGrid1 alt="" src={Back} onClick={handleBack} />
            </IconButton>
            <IconButton disabled={!previousPath}>
              <IconHugeviewGrid1
                alt=""
                src={Next}
                onClick={() => {
                  getDirectoryList(previousPath);
                  setFolderPath(previousPath);
                }}
              />
            </IconButton>
            {/* <IconButton>
              <IconHugeviewGrid1
                onClick={handleRootDirectory}
                alt=""
                src={Up}
              />
            </IconButton> */}
            <IconButton
              onClick={() => {
                getDirectoryList(folderPath);
              }}
            >
              <IconHugeviewGrid1 alt="" src={Refresh} />
            </IconButton>
            <Input>
              <FormLabel>
                <LabelInput>Label input</LabelInput>
              </FormLabel>
              <InputElement>
                <InputLeft>
                  <IconBtnSmallLeft alt="" src={FilePath} />
                  <Placeholder>
                    {editPath ? (
                      <InputBase
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleEditPath();
                          }
                        }}
                        placeholder=" Edit path here"
                        autoFocus
                        value={newPath}
                        onChange={handleFolderPath}
                      />
                    ) : (
                      <span>
                        <Breadcrumbs
                          maxItems={5}
                          separator={"/"}
                          aria-label="breadcrumb"
                        >
                          {breadcrumbs}
                        </Breadcrumbs>
                      </span>
                    )}
                  </Placeholder>
                  <IconBtnSmallLeft
                    onClick={() => handlePath()}
                    alt=""
                    src={Edit}
                  />
                </InputLeft>
                <ButtonGroup1 sx={{ borderRadius: "10px 0 0 10px" }}>
                  <IconBtnGroupSmall alt="" />
                  <Text17>Button</Text17>
                  <IconBtnSmallLeft
                    alt=""
                    onClick={editPath ? handleEditPath : null}
                    src={Next}
                  />
                </ButtonGroup1>
              </InputElement>
            </Input>
            <Input1>
              <InputElement1>
                <IconBtnSmallLeft alt="" src={SearchIcon} />
                {/* <Search>Search</Search> */}
                <InputBase placeholder="Search..." onChange={handleSearch} />
              </InputElement1>
            </Input1>
          </FrameDiv>

          <FrameParent>
            {loading ? (
              <>
                <Stack
                  spacing={2}
                  m={2}
                  direction="row"
                  style={{
                    position: "absolute",
                    top: "53%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CircularProgress size={20} />
                  <Typography>Loading...</Typography>
                </Stack>
              </>
            ) : (
              <Grid container m={0}>
                <Grid item xs={click ? 9 : 12}>
                  <TableComponent
                    data={data}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    action={actionType}
                    setClick={setClick}
                    click={click}
                    // share={handleShare}
                    previewdata={previewdata}
                    setItem={setItem}
                    setDirectory={setDirectory}
                    bucket={bucket}
                    setActiveObject={setActiveObject}
                    setEditorMode={setEditorMode}
                    setOpenPreview={setOpenPreview}
                    handleRename={renameFile}
                    setBucket={setBucket}
                    setLock={setLock}
                    setFolderPath={setFolderPath}
                    setOpenDialog={setOpenDialog}
                    setDialogTitle={setDialogTitle}
                    handleDelete={handleDelete}
                    setPaste={setPaste}
                    paste={paste}
                    directory={directory}
                    setCurrentRow={setCurrentRow}
                    currentRow={currentRow}
                    getDirectoryList={getDirectoryList}
                    getObjectDetails={getObjectDetails}
                    getDirectoryDetails={getDirectoryDetails}
                  />
                </Grid>
                {click && (
                  <FilePreview
                    data={previewdata}
                    setClick={setClick}
                    setCurrentRow={setCurrentRow}
                    setPreviewdata={setPreviewdata}
                  />
                )}
              </Grid>
            )}
          </FrameParent>
          <CreateDialog
            open={false}
            title={dialogTitle}
            children={<SessionExpired />}
          />

          <CreateDialog
            open={open}
            title={dialogTitle}
            // theme={theme}
            setLock={setLock}
            handleClose={() => setOpen(false)}
            close={setOpen}
            children={
              <UploadDialog
                // theme={theme}
                file={file}
                UploadDocuments={UploadDocuments}
                setFile={setFile}
                progressMap={progressMap}
                folderPath={folderPath}
                setOpen={setOpen}
                getDirectoryList={getDirectoryList}
                close={setOpen}
              />
            }
          />
          <Alerts
            severity={severity}
            handleClose={() => setToastAlert(false)}
            message={toastMessage}
            open={toastAlert}
          />
        </Box>
        {(actionType || paste) && (
          <Alert
            variant="alertToast"
            action={
              <Box display="flex">
                <Button
                  color="inherit"
                  variant="save"
                  style={{ marginRight: "65px", padding: '8px', textTransform: "capitalize" }}
                  onClick={() => {
                    if(selectedItems.length > 0 || paste?.location){
                      if (paste?.type === "Move") {
                        handleMove(paste.location, paste.settingId);
                      } else if (paste?.type === "Copy") {
                        handleCopy(paste.location, paste.settingId);
                      } else if (paste?.type === "Delete") {
                        handleDelete(paste.location);
                      } else if (paste?.type === "Unzip") {
                        handleUnzip(paste.location, paste.settingId);
                      } else if (actionType === "copy") {
                        setPaste({
                          type: "Copy",
                          location: selectedItems,
                          settingId: globalState.settingId,
                        });
                      } else if (actionType === "move") {
                        setPaste({
                          type: "Move",
                          location: selectedItems,
                          settingId: globalState.settingId,
                        });
                      } else if (actionType === "archieve") {
                        // handleArchieve(selectedItems);
                        setOpenDialog(true);
                        setDialogTitle("Archive Name");
                      } else if (actionType === "delete") {
                        // handleDelete(selectedItems);
                        setHandleConfirm({
                          open:'true',
                          message:"Are you sure want to delete?",
                        })
                      }
                    }
                  }}
                >
                  {paste?.type === "Move"
                    ? "Move here"
                    : paste?.type === "Delete"
                    ? "Delete"
                    : paste?.type === "Unzip"
                    ? "Extract"
                    : paste
                    ? "Paste"
                    : `${actionType === "delete" ? "Delete" : actionType} (${
                        selectedItems.length
                      })`}
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  variant="cancel"
                  sx={{ p: 1, mr: 5 }}
                  onClick={() => {
                    setSelectedItems([]);
                    setPaste();
                    setActionType();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            }
            // style={{ }}
            icon={<img src={DialogInfo} width={20} alt="info" />}
          >
            <Typography variant="alertText">
              {paste?.type === "Delete"
                ? "Are you want to delete?"
                : paste?.type === "Unzip"
                ? "Select the source folder to extract the file"
                : paste
                ? "Select the source folder to paste the file"
                : `Select the item that you want to ${actionType}`}
            </Typography>
          </Alert>
        )}
        <CreateDialog
          open={openDialog}
          title={dialogTitle}
          handleClose={setOpenDialog}
          lock={lock}
          setLock={setLock}
          // title={lock?.name ?  lock.name : dialogTitle}

          children={
            <CreateUser
              close={setOpenDialog}
              dialogTitle={dialogTitle}
              lock={lock}
              setLock={setLock}
              formSubmit={
                dialogTitle === "Create New File"
                  ? createFile
                  : dialogTitle === "Create Folder"
                  ? createFolder
                  : dialogTitle === "Lock" || dialogTitle === "Unlock"
                  ? LockObject
                  : dialogTitle === "Archive Name"
                  ? handleArchieve
                  : null
              }
            />
          }
        />

        <FullDialog
          // theme={theme}
          folderPath={folderPath}
          getDirectoryList={getDirectoryList}
          open={openPreview}
          editor={editorMode}
          setEditor={setEditorMode}
          setActiveObject={setActiveObject}
          activeObject={activeObject}
          setOpenPreview={setOpenPreview}
        />
      </Sidebar>
      <CreateDialog
        open={sessionExpired}
        title={dialogTitle}
        children={<SessionExpired />}
      />
      <Confirmation
        open={handleConfirm.open}
        handleClose={()=>{
          setHandleConfirm(false)
        }}
        message={handleConfirm.message}
        ok={()=>{
          handleDelete(selectedItems)
        }}
      />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  setFiles: (files) => dispatch(setFiles(files)),
  setProgress: (progress) => dispatch(setProgress(progress)),
  setSettingId: (settingId) => dispatch(setSettingId(settingId)),
  setPassword: (password) => dispatch(setPassword(password)),
});

export default connect(mapStateToProps, dispatchToProps)(Home);
