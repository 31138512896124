import {
    Box,
    Container,
    FormControl,
    Card,
    FormHelperText,
  } from "@mui/material";
  import React, { useState } from "react";
  import Logo from "./../../images/home/logo.png";
  import { MUI } from "../../components/CustomComponents";
  import { Formik } from "formik";
  import * as Yup from "yup";
  import { useNavigate } from "react-router-dom";
  import styled from "styled-components";
  import { useLocation } from "react-router-dom";
  import { loginApi } from "../../_services/login";
  import Alert from "../../components/alert";
  const PREFIX = "NormalCard";

  const classes = {
    container: `${PREFIX}-container`,
    anchor: `${PREFIX}-anchor`,
  };
  
  // const CustomTypography = styled.Typography`
  // marginTop: "8px",
  // fontFamily: "Inter",
  // textDecoration: "underline",
  // fontSize: 12,
  // fontWeight: "600",
  // cursor: "pointer",
  // `;
  const Root = styled("div")(({ theme }) => ({
    [`& .${classes.container}`]: {
      height: "100vh",
      alignItems: "center",
      display: "flex",
    },
  }));
  
  const ResetPassword = () => {
    const { CustomTypography, CustomInput, LoginButton } = MUI;
    const location = useLocation()
    const navigate = useNavigate();
    const renderInputField = (
      label,
      name,
      value,
      placeholder,
      handleChange,
      touched,
      error,
      type
    ) => {
      return (
        <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
          <CustomTypography>{label}</CustomTypography>
          <CustomInput
            error={Boolean(touched && error)}
            name={name}
            value={value}
            type={type ? type : "text"}
            onChange={handleChange}
            placeholder={placeholder}
            size="small"
          />
          <FormHelperText error sx={{ fontFamily: "Inter" }}>
            {touched && error}
          </FormHelperText>
        </FormControl>
      );
    };

  
    const renderTitle = (title, description) => {
      return (
        <Box
          alignItems={"center"}
          mb={3}
          display={"flex"}
          flexDirection={"column"}
        >
          <img
            style={{ marginBottom: "1rem" }}
            src={Logo}
            width={300}
            alt="logo"
          />
          <CustomTypography
            variant="h6"
            sx={{ color: "#101828", fontSize: "30px" }}
          >
            {title}
          </CustomTypography>
          <CustomTypography sx={{ color: "#475467" }}>
            {description}
          </CustomTypography>
        </Box>
      );
    };
  
    const handleOnSubmit = (values) => {
      const data = {
        "email": location.state.email,
        "password":values.password,
        "OTP": values.otp
      }
      try{
        loginApi.resetPassword(data).then((res) => {
          if(res.data ==="Invalid OTP"){
            setOpen(true);
            setSeverity('error')
            setMessage('Invalid OTP') 
          }else if (res?.data?.message === "Password changed successfully") {
            setOpen(true);
            setSeverity('success')
            setMessage('Password changed successfully')
            setTimeout(()=>{
              navigate('/signin')
            },[5000])
          } else {
            setOpen(true)
            setSeverity('error')
            setMessage('Password change failed')
          }
        });
      }catch{
        setOpen(true)
      }
    };
  
    const [severity,setSeverity]= useState(false)
    const [message,setMessage]= useState(false)
    const [open,setOpen]= useState(false)

   
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


    return (
      <Root>
        <Container maxWidth="sm" className={classes.container}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "1px 1px 10px 0px lightGrey",
              height: "80vh",
              paddingTop: "40px",
            }}
          >
            <Formik
              initialValues={{
                otp: "",
                confirmPassword: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                otp: Yup.string().required("Please enter otp"),
                confirmPassword: Yup.string()
                .required("Please enter confirm password")
                .oneOf([Yup.ref("password"), null], "Passwords must match"),
                password: Yup.string().required(" Please enter password")
                .matches(passwordRegex, "Password must contain atleast minimum eight characters,one letter, one number and one special character")
                ,
              })}
              onSubmit={(values) => {
                handleOnSubmit(values);
              }}
            >
              {({
                errors,
                setFieldValue,
                handleChange,
                handleSubmit,
                touched,
                submitCount,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
  
                  <Container maxWidth={"xs"}>
                    {renderTitle("", "Reset Password")}
                    <Box sx={{ minHeight: 300 }}>
                      {renderInputField(
                        "Email OTP*",
                        "otp",
                        values.otp,
                        "Enter otp sent to email",
                        handleChange,
                        touched.otp,
                        errors.otp
                      )}
                      {renderInputField(
                        "Password*",
                        "password",
                        values.password,
                        "Enter new password",
                        handleChange,
                        touched.password,
                        errors.password,
                        "password"
                      )}
  
                      {renderInputField(
                        "Confirm Password*",
                        "confirmPassword",
                        values.confirmPassword,
                        "Enter confirm password",
                        handleChange,
                        touched.confirmPassword,
                        errors.confirmPassword,
                        "password"
                      )}
                      
                    </Box>
                    <LoginButton
                      type={"submit"}
                      fullWidth
                      sx={{ textAlign: "center" }}
                    >
                      Reset Password
                    </LoginButton>
  
                    <Box
                      mt={3}
                      sx={{ borderBottom: "1px solid lightGrey" }}
                    ></Box>
                    <LoginButton
                      // type={"submit"}
                      onClick={() => navigate("/signin")}
                      // variant="outlined"
                      fullWidth
                      sx={{ textAlign: "center" }}
                    >
                      Login to workspace
                    </LoginButton>
                  </Container>
                </form>
              )}
            </Formik>
          </Card>
        </Container>
      <Alert severity={severity} message={message} open={open} />

      </Root>
    );
  };
  
  export default ResetPassword;
  