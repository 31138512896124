import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Stack,
  IconButton,
} from "@mui/material";
import Folder from "./../../images/files/tableFile.jsx";
import SortIcon from "./../../images/sortIcon.svg";
import customStyles1 from "../../components/Table/index.js";
import { apiData } from "../../_services/home.js";
import { FileType } from "../../components/Function";
import Alerts from "../../components/alert.jsx";
import { Delete, Restore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";
import { crypto } from "../../components/Security/crypto.js";
import CreateDialog from "../../components/Dialog/CreateDialog.jsx";
import SessionExpired from "../../components/Dialog/SessionExpired.jsx";
import BackDrop from "../../components/Backdrop/backdrop.jsx";
const TrashTabe = (props) => {
  const [dialogTitle, setDialogTitle] = useState("");
  const [data, setData] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [toastAlert, setToastAlert] = useState(false);
  const [open, setOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState();
  const state = useSelector((state) => state.authReducer);

  const fileType = (type) => {
    return FileType.getFileType(type);
  };
  const GetTrashList = async () => {
    try {
      setOpen(true);
      await apiData.getTrashList(state.settingId).then((res) => {
        setOpen(false);
        if (res.status === 200) {
          const decreptedData = crypto.decryption(res.data.data);
          setData(decreptedData);
        } else if (res.status === 401) {
          setDialogTitle("Important Notice");
          setSessionExpired(true);
        } else if (res.status === 404) {
          setData([]);
          setSeverity("success");
          setToastAlert(true);
          setToastMessage(res.data.message ? res.data.message : res.data.error);
        } else {
          setSeverity("error");
          setToastAlert(true);
          setToastMessage(res.data.message ? res.data.message : res.data.error);
        }
      });
    } catch (error) {
      setSeverity("error");
      setToastAlert(true);
      setToastMessage("Something went wrong");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (values, bucket) => {
    const data = {
      setting_id: state.settingId,
      source: [values],
    };
    setOpen(true);
    apiData.deleteObject(data, bucket).then((res) => {
      if (res.status === 200) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage("Deleted successfully");
        setTimeout(() => {
          GetTrashList();
        }, 1000);
      } else if (res.status === 401) {
        setDialogTitle("Important Notice");
        setSessionExpired(true);
      } else {
        // setLoading(false);
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  const handleRestore = (id) => {
    setOpen(true);
    apiData.restoreItem(id).then((res) => {
      if (res.status === 200) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage("Restored successfully");
        setTimeout(() => {
          GetTrashList();
        }, 500);
      } else if (res.status === 401) {
        setDialogTitle("Important Notice");
        setSessionExpired(true);
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  useEffect(() => {
    GetTrashList();
  }, []);
  const customStyles = customStyles1;

  const viewColumns = [
    {
      name: "Title",
      // selector: (row) => row.name,
      sortable: true,
      cell: (row, column) => {
        const isSelected = "props.selectedItems.some((r) => r === row.path)";
        return (
          <Grid
            display="flex"
            alignItems="center"
            sx={{
              whiteSpace: "wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            <Box
              sx={{
                background:
                  props?.theme === "default"
                    ? isSelected
                      ? "#fff"
                      : "#F6EAE6"
                    : isSelected
                    ? "#fff"
                    : "#C9F0FC",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 20,
                height: 20,
                borderRadius: 1,
              }}
            >
              {row.type === "folder" ? (
                <Folder theme={props.theme} />
              ) : (
                <img src={fileType(row.path)} alt="" width={15} />
              )}
            </Box>
            <Tooltip title={row.name}>
              <Typography variant="settingLable" ml={1}>
                {row.name}
              </Typography>
            </Tooltip>
          </Grid>
        );
      },
    },
    // {
    //   name: "Bucket Name",
    //   selector: (row) => row.bucket ?row.bucket : 'N/A',
    //   sortable: true,
    // },

    {
      name: "Content Type",
      sortable: true,
      selector: (row) => row.type?.toUpperCase(),
    },
    // {
    //   name: "Path",
    //   // sortable: true,
    //   selector: (row) => row.path,
    // },
    {
      name: "Last Modified",
      sortable: true,
      selector: (row) =>
        row.created_at ? moment(row.created_at).format("ll") : "N/A",
    },
    {
      name: "Action",
      right: true,
      cell: (row) => {
        return (
          <Stack direction={"row"}>
            <Tooltip title="Restore">
              <IconButton
                disableRipple
                sx={{ padding: "0 10px" }}
                onClick={() => {
                  handleRestore(row.id);
                }}
              >
                <Restore />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                disableRipple
                sx={{ padding: 0 }}
                onClick={() => handleDelete(row.path, row.bucket)}
              >
                {/* <img src={Delete} alt="delete"/> */}
                <Delete />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        m: 1,
        border: data.length > 0 && "1px solid lightGrey",
        borderRadius: "8px",
      }}
    >
      <DataTable
        dense
        noDataComponent="No data available"
        pagination
        noBorder
        sortIcon={
          <img style={{ margin: "0px 0 -2px 6px " }} src={SortIcon} alt="" />
        }
        columns={viewColumns}
        data={data}
        selectableRows={props.selectableRows}
        customStyles={customStyles}
        highlightOnHover
      />

      <Alerts
        severity={severity}
        handleClose={() => setToastAlert(false)}
        message={toastMessage}
        open={toastAlert}
      />
      <CreateDialog
        open={sessionExpired}
        title={dialogTitle}
        children={<SessionExpired />}
      />
     <BackDrop open={open} handleClose={handleClose}/>
    </Box>
  );
};

export default TrashTabe;
