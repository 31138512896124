import React from 'react';

const Folder = (({active})=>{
  return(

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
  <path stroke={active ? "#FFF" :"#404B5A"} stroke-linecap="round" stroke-width="1.5" d="M5.833 5.833h8.125c1.756 0 2.634 0 3.264.422a2.5 2.5 0 0 1 .69.69c.421.63.421 1.508.421 3.263 0 2.926 0 4.39-.702 5.44a4.166 4.166 0 0 1-1.15 1.15c-1.05.702-2.513.702-5.44.702H10c-3.928 0-5.893 0-7.113-1.22-1.22-1.22-1.22-3.185-1.22-7.113V6.62c0-1.514 0-2.27.317-2.838a2.5 2.5 0 0 1 .964-.965C3.516 2.5 4.273 2.5 5.787 2.5c.97 0 1.455 0 1.88.16.968.363 1.368 1.243 1.805 2.118L10 5.833"/>
</svg>

      );
})

export default Folder;
