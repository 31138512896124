import * as React from "react";
import { Box, Button, Typography, DialogActions } from "@mui/material";
import { connect } from "react-redux";
import { logoutSuccess } from "../../store/bugs";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const SessionExpired = ((props) => {
const navigate = useNavigate()
const cookies = new Cookies();
  const Logout=(()=>{
    props.logoutSuccess();
    cookies.remove('token');
    cookies.remove('bucketName');
    navigate("/signin", { replace: true });
  })
  return (
    <>
      <Box
        spacing={1}
        sx={{
          minHeight: "70px",
        }}
      >
        <Typography
          variant="folderSize"
          sx={{ color: "#041527", fontSize: "14px" }}
        >
          Token Expired. Please Login again to continue!
        </Typography>
      </Box>
      <DialogActions>
        <Button variant="save" onClick={Logout}>
          Re-login
        </Button>
      </DialogActions>
    </>
  );
})

const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
});

export default connect(null, dispatchToProps)(SessionExpired);
