import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  InputLabel,
  Box,
  Button,
  InputBase,
  FormHelperText,
  Grid,
  Typography,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  IconButton,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFolder from "./../../images/home/tableFile.jsx";
import { FileType } from "../../components/Function";
import { apiData } from "../../_services/home.js";
import CopyIcon from "./../../images/alert/CopyIcon.svg";
import CreateDialog from "./../../components/Dialog/CreateDialog.jsx";
import Alerts from "../../components/alert";
import SessionExpired from "./../../components/Dialog/SessionExpired.jsx";
import { useSelector } from "react-redux";
import { crypto } from "../../components/Security/crypto.js";
const PREFIX = "Share";

const classes = {
  inputbase: `${PREFIX}-inputbase`,
  textArea: `${PREFIX}-textArea`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.inputbase}`]: {
    border: "1px solid #D9D9D9",
    padding: "5px 12px",
    width: "100%",
    borderRadius: "2px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "400",
    "&::placeholder": {
      color: "#00000040",
    },
  },

  [`& .${classes.textArea}`]: {
    border: "1px solid #D9D9D9",
    padding: "5px 12px",
    width: "100%",
    borderRadius: "2px",
    maxWidth: "100%",
    minWidth: "100%",
    "&:focus": {
      border: "1px solid #D9D9D9 !important",
    },
    "&::placeholder": {
      color: "#00000040",
    },
  },
}));

const fileType = (type) => {
  return FileType.getFileType(type);
};

const Share = (props) => {
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [data, setData] = useState(false);
  const globalState = useSelector((state) => state.authReducer);
  const handleShare = (values) => {
    const payload = {
      setting_id: globalState.settingId,
      prefix: values.location,
      expiry_time: values.time*60,
      email_share: values.email,
    };
    setLoading(true);
    apiData.shareLink(payload).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        if (values.email) {
          setSeverity("success");
          setToastAlert(true);
          setToastMessage(res.data.message ? res.data.message : res.data.error);
          setTimeout(() => {
            props.close();
          }, 1500);
        } else {
          const decreptedData = crypto.decryption(res?.data?.data);
          setData(decreptedData);
        }
        setLoading(false);
      } else if (res.status === 401) {
        setDialogTitle("Important Notice");
        setSessionExpired(true);
      } else {
        setLoading(false);
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  // eslint-disable-next-line no-useless-escape
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return (
    <Root>
      <Formik
        initialValues={{
          time: 30,
          location: props?.data ? props?.data?.prefix : "",
          email: "",
          url_type: "1",
        }}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Please enter expiry time"),
          email: Yup.string().email("Enter a valid email"),
        })}
        onSubmit={(values) => {
          handleShare(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          submitCount,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div style={{ minHeight: "100px" }}>
              <Grid container>
                <Grid item xs={4}>
                  <InputLabel htmlFor="bootstrap-input">File Name</InputLabel>
                  <div style={{ display: "flex" }}>
                    {props.data.type === "folder" ||
                    (props.data.Name && props.data.Name[0]) ? (
                      <CustomFolder theme={props.theme} />
                    ) : (
                      <img
                        src={fileType(props.data.prefix)}
                        alt=""
                        width={25}
                      />
                    )}
                    <Tooltip
                      title={props.data.name}
                      style={{ marginTop: "8px" }}
                    >
                      <Typography variant="share">{props.data.name}</Typography>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <InputLabel htmlFor="bootstrap-input">
                    Expires in minutes
                  </InputLabel>
                  <InputBase
                    style={{
                      border:
                        submitCount > 0 &&
                        touched.time &&
                        errors.time &&
                        "1px solid red",
                    }}
                    // error={}
                    name="time"
                    value={values.time}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="MyCustomInput1"
                    placeholder=""
                    type="number"
                    size="small"
                    className={classes.inputbase}
                  />
                  {submitCount > 0 && touched.time && errors.time && (
                    <FormHelperText error>{errors.time}</FormHelperText>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={8}
                  lg={8}
                  className={classes.gridPadding}
                >
                  <FormControl
                    component="fieldset"
                    error={Boolean(touched.spa_type && errors.spa_type)}
                  >
                    <FormLabel
                      component="legend"
                      sx={{ fontSize: "12px", mt: 2 }}
                    >
                      Get Link through
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="payment_mode"
                      name="url_type"
                      value={values.url_type}
                      id="payment_mode"
                      color="primary"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("url_type", e.target.value);
                        if (e.target.value === "1") {
                          setFieldValue("email", "");
                        }
                      }}
                    >
                      <FormControlLabel
                        disabled={data}
                        value={"1"}
                        control={<Radio color="primary" />}
                        label="Url"
                      />
                      <FormControlLabel
                        disabled={data}
                        value={"2"}
                        control={<Radio color="primary" />}
                        label="Email"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {touched.url_type && errors.url_type}
                    </FormHelperText>
                  </FormControl>
                  {/* <ErrorFocus /> */}
                </Grid>
                {values.url_type === "2" && (
                  <Grid item xs={12} mb={2}>
                    <InputLabel
                      htmlFor="bootstrap-input"
                      style={{ marginTop: "10px" }}
                    >
                      Email to
                    </InputLabel>
                    <Box
                      style={{
                        display: "flex",
                        padding: 0,
                        alignItems: "center",
                        border: "1px solid #D9D9D9",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box style={{ display: "flex", ml: 0.4 }}>
                        <InputBase
                          name="email"
                          onChange={handleChange}
                          variant="standard"
                          style={{ width: "43ch", marginLeft: "4px" }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>

            {/* <DialogActions> */}
            <Button
              variant="save"
              className={classes.contained}
              type="submit"
              disabled={
                data ||
                loading ||
                (values.url_type === "2" && !values.email.match(mailformat))
              }
              onClick={() => handleShare(values)}
            >
              {values.url_type === "1" ? "Generate link" : "Send"}
            </Button>
            {data ? (
              <div style={{ marginTop: "2rem" }}>
                <div>
                  <InputLabel htmlFor="bootstrap-input">Copy Link</InputLabel>
                  <Box
                    style={{
                      display: "flex",
                      padding: 0,
                      alignItems: "center",
                      border: "1px solid #D9D9D9",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box style={{ display: "flex", ml: 0.4 }}>
                      <Typography
                        sx={{
                          width: "38ch",
                          marginLeft: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data}
                      </Typography>
                    </Box>
                    <div
                      style={{
                        background: "#4CB5F5",
                        padding: "5px  5px 0px",
                        borderRadius: 0,
                        cursor: "copy",
                      }}
                      onClick={() => navigator.clipboard.writeText(data)}
                    >
                      <Tooltip title={"Copy"}>
                        <img src={CopyIcon} alt="prefix" />
                      </Tooltip>
                    </div>
                  </Box>
                </div>
              </div>
            ) : null}
          </form>
        )}
      </Formik>
      {/* <ToastMessage
             open={toastAlert}
             message={toastMessage}
             close={setToastAlert}
             severity={severity}
           /> */}
      <Alerts
        severity={severity}
        handleClose={() => setToastAlert(false)}
        message={toastMessage}
        open={toastAlert}
      />
      <CreateDialog
        open={sessionExpired}
        title={dialogTitle}
        children={<SessionExpired />}
      />
    </Root>
  );
};

export default Share;
