import Dialog from "@mui/material/Dialog";
import {
  AppBar,
  Button,
  Typography,
  Toolbar,
  IconButton,
  DialogContent,
  Stack,
  CircularProgress,
  Grid,
  Box,
  MenuItem,Tooltip
} from "@mui/material";
import { connect } from "react-redux";
import { logoutSuccess } from "../../store/bugs";
import Logo from '../../images/home/logo.png'
import CloseIcon from "./../../images/home/borderedClose.svg";
import React, { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import { apiData } from "../../_services/home.js";
import ToastMessage from "../../components/Alert";
import { Editor } from "@monaco-editor/react";
import Unsupported from "./../../images/files/unsupported.jsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StyledMenu from "../Menu";
import axios from "axios";
import CreateDialog from "./CreateDialog";
import CreateUser from '../../pages/Folders/CreateFolder.jsx'
import 'video.js/dist/video-js.css';
import VideoJs from "./VideoJs"; 
import { ReactPanZoom } from "./../../components/Dialog/imageViewer";
import SaveIcon from './../../images/home/save.svg'
import SaveAsIcon from './../../images/home/saveAs.svg'
import { useSelector } from "react-redux";
import { crypto } from "../Security/crypto.js";

const FullDialog = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toastAlert, setToastAlert] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [toastMessage, setToastMessage] = useState();
  const [codeData, setCodeData] = useState(null);
  const [openDialog,setOpenDialog] = useState(false);
  const [language,setLanguage] = useState('');
  const [editor, setEditor] = useState(props.editor === "edit" ? true : false);
  const [imageEditor, setImageEditor] = useState(
    props.editor === "image" ? true : false
  );
  const state = useSelector((state)=> state.authReducer)
  const [imageUrl,setImageUrl] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  useEffect(() => {
    setLoading(true);
    if (props.activeObject?.prefix) {
      getObjectDetails();
      setEditor(false);
    } else {
      setLoading(false);
      setCodeData("");
    }
    if(props.activeObject){
      let extension = props.activeObject.title.substring(props.activeObject.title.lastIndexOf('.') + 1);
      setLanguage(extension ==='js'  ? 'javascript' : (extension ==='ts' || extension ==='tsx') ?'xml' :  extension)
    }
  }, [props.activeObject]);
  
  const getObjectDetails = () => {
    setLoading(true);
    apiData
      .getObjectDetails(state.settingId, props.activeObject?.prefix,props.activeObject?.password)
      .then((res) => {
        setLoading(false);
        if (res.status ===200) {
          const decreptedData = crypto.decryption(res.data.data)
          setLanguage(decreptedData.type ==='js'  ? 'javascript' : (decreptedData.type ==='ts' || decreptedData.type ==='tsx') ?'typescript' :  decreptedData.type)
          if(!decreptedData.url){
          setSeverity("error");
          setToastAlert(true);
          setTimeout(()=>{
            handleClose()
          },1000)
          setToastMessage("Invalid Credentials");
          }else{
            setData(decreptedData);
            fetchData(decreptedData.url);
          }
        } else {
          setSeverity("error");
          setToastAlert(true);
          setToastMessage(res.data.message ? res.data.message : res.data.error);
        }
      });
  };

  const fetchData = async (url) => {
    setCodeData(null);
    try {
      const response = await fetch(url);
      const data = await response.text();
      setCodeData(data);
    } catch (error) {
      console.error("Error fetching URL:", error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

const saveObjectUrl =(async ()=>{
  const pathDetails = {
    file: props.activeObject?.prefix
      ? props.activeObject?.prefix
      : props.activeObject.newFilePath,
  };
  const response =await apiData.uploadFiles(pathDetails,state.settingId,"save_object");
  if (response.status === 200) {
    const decryptedData = crypto.decryption(response?.data?.data)
    const url = decryptedData.url;
    const type =decryptedData.file_type
    SaveObject(url,type)
  }
})
  const supportedFormatsbyFileViewer = ["pdf", "csv",  "docx", "xlsx"]; // Add supported file formats here
  const audioFormats = [
    "mp3",
    "wav",
    "ogg",
    "m4a",
    "aac",
    "flac",
    "opus",
    "m3u",
    "webm",
    "mp4",
    "ogv",
    "flv",
    "mov",
    "wvm",
    "mkv",
    "mpeg",
    "3gp",
    "m4v",
    "m3u8",
  ]; // Add supported file formats here
  const codeFormat = [
    "yaml",
    "bat",
    "htaccess",
    "class",
    "cpp",
    "cs",
    "go",
    "js",
    "json",
    "py",
    "sql",
    "swift",
    "vb",
    "txt",
    "css",
    "html",
    "pl",
    "rb",
    "sh",
    "c",
    "h",
    "java",
    "kml",
    "test","ts","tsx","jsx"
  ]; // Add supported file formats here

  const imageFormat = [
    "jpeg",
    "jpg",
    "svg",
    "png",
    "webp",
    "bmp",
    "ico",
    "tga",
    "psd",
    "eps",
    "ai",
    "pdf",
    "gif",
    "jfif",
    "wbmp",
    "jpe",
    "jps",
    "dds",
  ]; // Add supported file formats here

  const isFormatSupported = supportedFormatsbyFileViewer.some(
    (format) => data && data?.name.toLowerCase().endsWith(`.${format}`)
  );

  let isCodeFormatSupported = codeFormat.some(
    (format) => data && data?.name.toLowerCase().endsWith(`.${format}`)
  );
  const isAudioFormatSupported = audioFormats.some(
    (format) => data && data?.name.toLowerCase().endsWith(`.${format}`)
  );
  const isImageFormatSupported = imageFormat.some(
    (format) => data && data?.name.toLowerCase().endsWith(`.${format}`)
  );

  const image = [
    "jpg",
    "jpeg",
    "svg",
    "png",
    "cr2",
    "dng",
    "erf",
    "heic",
    "heif",
    "jfif",
    "jp2",
    "nrw",
    "orf",
    "pef",
    "pes",
    "raf",
    "rw2",
    "webp",
    "cur",
    "dds",
    "exr",
    "fts",
    "gif",
    "hdr",
    "ico",
    "jpe",
    "jps",
    "mng",
    "pam",
    "bmp",
    "pbm",
    "pcd",
    "pcx",
    "pfm",
    "pgm",
    "picon",
    "pict",
    "pnm",
    "ppm",
    "psd",
    "ras",
    "sfw",
    "sgi",
    "tga",
    "tiff",
    "wbmp",
    "webp",
    "xbm",
    "wpg",
    "x3f",
    "xcf",
    "xpm",
    "xwd",
  ];

  const isImage = image.some(
    (format) => data && data?.name.toLowerCase().endsWith(`.${format}`)
  );

  const handleClose = () => {
    props.setOpenPreview(false);
    // props.setPrefix();
    setImageEditor(false);
    props.setEditor(false);
    setTimeout(() => {
      setData(null);
    }, 300);
  };

  const SaveObject = (url,type) => {
    setAnchorEl(null)
    const fileToSave = new File([codeData], { type: 'text/plain' });
    delete axios.defaults.headers.common["Authorization"];
    axios
      .put(url, fileToSave, {
        headers: {
          "Content-Type": type,
          'x-ms-blob-type': 'BlockBlob'
        },
      })
      .then((res) => {
        setAnchorEl(null);
        if (res.status === 200 || res.status === 201) {
          setToastAlert(true);
          setSeverity("success");
          setToastMessage("File saved");
          setOpenDialog(false)
          setTimeout(()=>{
            props.getDirectoryList(props.folderPath)
          },2000)
        }
      });
  };
  const SaveAsObject =(async(values)=>{
    const pathDetails = {
      file: `${props.folderPath}${values.title}`
    };
    const response = await apiData.uploadFiles(pathDetails,state.settingId,"saveas_object");
    if (response.status === 200) {
      const decryptedData = crypto.decryption(response?.data?.data)
      const url = decryptedData?.url;
      const type =decryptedData?.file_type
      SaveObject(url,type)
    }
  })

  useEffect(()=>{
  setImageUrl(`https://www.photopea.com/#%7B%22files%22:%5B%22${data?.url}%22%5D,%22environment%22:%7B%7D%7D`)
  setImageEditor(props.editor ==="image" ? true:false)
  setEditor(props.editor ==="edit" ? true:false)
},[data])

const videoJsOptions = {
  controls: true,
  sources: [
    {
      src:data?.url
    }
  ]
};

  return (
    <div>
      <Dialog
        sx={{
          "&.MuiDialog-paper": {
            width: "100%",
            position: "relative",
            overflow: "unset",
            maxHeight: "100vh",
          },
        }}
        fullScreen
        onClose={handleClose}
        // TransitionComponent={Transition}
        open={props.open}
        
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar  style={{ padding: "10px 25px", backgroundColor: "#fff", display:'flex', justifyContent:"space-between" }}>
            <Box sx={{flex:1}}  alignItems="center" display="flex">
              <img src={Logo} width="200" alt="logo"/>
            </Box>
              <Tooltip position='bottom' title={props.activeObject.title}>
            <Typography variant="fileName" sx={{  ml:3 ,flex:1, textAlign:'center'}}>
              {props.activeObject.title}
            </Typography>
              </Tooltip>
         <Box sx={{flex:1 , textAlign:'end'}}>
         {(isImageFormatSupported && !imageEditor && !editor) ? (
              <Button
                variant="save"
                style={{ margin: "0 2rem" }}
                onClick={() => setImageEditor(true)}
              >
                Open with Image Editor
              </Button>
            ) : null}
            {(isCodeFormatSupported && props.editor !== "image") || editor   || props.activeObject.newFilePath ? (
              <Button
                variant="save"
                style={{ margin: "0 6rem" }}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon sx={{ color: "#fff" }} />}
              >
                save
              </Button>
            ) : null}
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <img width={25} onClick={handleClose} src={CloseIcon} alt="" />
            </IconButton>
         </Box>
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            overflow: "unset",
            overflowY: "hidden",
            padding: 0,
          }}
        >
          <ToastMessage
            open={toastAlert}
            message={toastMessage}
            close={() => setToastAlert(false)}
            severity={severity}
          />
          {((loading || data === null) && !props.activeObject.newFilePath) || (props.editor === "image" &&data === null) ? (
            <Stack
              spacing={2}
              m={2}
              direction="row"
              style={{
                position: "absolute",
                top: "53%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress
                sx={{
                  color: props.theme === "default" ? "#4CB5F5" : "#4CB5F5",
                }}
                size={20}
              />
              <Typography>Loading</Typography>
            </Stack>
          ) : (isCodeFormatSupported && props.editor !== "image") || editor  || props.activeObject.newFilePath ? (
            <Stack
              direction="row"
              style={{
                display: "grid",
                placeItems: "center",
                height: "calc(100vh - 64px)",
                width: "100%",
              }}
            >
              <Editor
                width={"100%"}
                height={"100%"}
                language={language}
                theme="vs-dark"
                onChange={(value) => {
                  setCodeData(value);
                }}
                value={
                  codeData !== null
                    ? codeData
                    : "Please wait, code is been loading..."
                }
              />
            </Stack>
          ) : data?.type === "pdf" ||
            imageEditor ||
            props.editor === "image" ? (
            <iframe
              title="title"
              style={{
                height: "calc(100vh - 64px)",
                lineHeight: "0",
                width: "100%",
                outline: "none",
                border: "none",
              }}
              src={
                imageEditor 
                  ? imageUrl
                  : data?.url
              }
            ></iframe>
          ) : data && isFormatSupported ? (
            <Grid sx={{ mt: "100px", height: "calc(100vh - 70px)" }}>
              <FileViewer
                sx={{
                  display: "flex",
                  margin: "auto",
                  width: "100vw",
                  marginTop: "100px",
                  alignItems: "center",
                }}
                fileType={data?.type}
                filePath={data?.url}
              />
            </Grid>
          ) : isAudioFormatSupported ? (
            <Stack
              direction="row"
              style={{
                display: "grid",
                height: "100%",
                placeItems: "center",
              }}
            >
      
      <VideoJs options={videoJsOptions} />
            </Stack>
          ) : isImageFormatSupported ? (
            <Stack sx={{height: "calc(100vh - 70px)" ,margin:'4px' ,alignItems:"center", justifyContent:'center'}}>
            {/* <img
              src={data.url}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              loading="lazy"
              alt=""
            /> */}
            <ReactPanZoom
              alt="cool image"
              image={data?.url}
            />
            </Stack>
          ) : (
            <Stack
              spacing={2}
              m={2}
              direction="column"
              textAlign={"center"}
              alignItems={"center"}
              style={{
                position: "absolute",
                top: "53%",
                maxHeight: "70vh",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Unsupported theme={props.theme} />
              <Typography
                variant="unsupportTitle"
                style={{ marginTop: "80px" }}
              >
                Unsupport File Format
              </Typography>
              <Typography
                variant="unsupportSubTitle"
                style={{ marginTop: "30px" }}
              >
                The file you opened is in unsupported format. Unable to open
              </Typography>
              <Box display="flex">
                {/* <a
                  href={data?.url}
                  style={{ all: "unset" }}
                  Download="test_image"
                >
                  <Button
                    variant="save"
                    // onClick={()=>setEditor(true)}
                    style={{
                      marginTop: "80px",
                      backgroundColor:
                        props.theme === "default" ? "#FFBC0F" : "#4CF5D7",
                      padding: "7px 15px",
                    }}
                  >
                    Download
                  </Button>
                </a> */}
                <Button
                  variant="save"
                  onClick={() =>
                    isImage ? setImageEditor(true) : setEditor(true)
                  }
                  style={{ marginTop: "80px", marginLeft: "2rem" }}
                >
                  {`Open with ${isImage ? "image" : ""} editor`}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  style={{
                    marginTop: "80px",
                    marginLeft: "2rem",
                  }}
                >
                  Back
                </Button>
              </Box>
            </Stack>
          )}
        </DialogContent>
        <StyledMenu
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={saveObjectUrl} style={{ width: "95%", margin: "0px auto" }}>
            <img src={SaveIcon} alt="save"/>
            <Typography  variant="menu" ml={1}>
              {" "}
              Save
            </Typography>
          </MenuItem>
          <MenuItem
            style={{ width: "95%", margin: "0px auto" }}
            onClick={()=> 
              {
              setOpenDialog(true)
              setAnchorEl(null)
            }}
          >
             <img src={SaveAsIcon} alt="saveas"/>
            <Typography  variant="menu" ml={1}>
              {" "}
              Save as
            </Typography>
          </MenuItem>
        </StyledMenu>
      </Dialog>
      <CreateDialog
              theme={props.theme}
              open={openDialog}
              title={"Save As"}
              handleClose={setOpenDialog}
              children={
                <CreateUser close={setOpenDialog} formSubmit ={SaveAsObject}/>
              }
            />
    </div>
  );
};

const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
});

export default connect(null, dispatchToProps)(FullDialog);
